import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import PoweredByDoctible from '../../../components/PoweredByDoctible';
import Localize from '../context/Localize';

const styles = theme => ({
  wrapper: {
    textAlign: 'center',
    padding: '40px 0 20px 0',
    fontSize: '16px',
    [theme.breakpoints.up('md')]: {
      padding: '80px 0 40px 0',
      fontSize: '16px',
    },
  },
});

const Footer = ({ classes }) => (
  <Localize.Consumer>
    {(t = {}) => (
      <div className={classes.wrapper}>
        {/* i18n-tasks-use t('base_react.no_filter_review_request.powered_by') */}
        <PoweredByDoctible preText={t.powered_by} />
      </div>
    )}
  </Localize.Consumer>
);

Footer.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(Footer);
