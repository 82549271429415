import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';


export default function Wrapper({ children, location, hide }) {

  if (hide) {
    return children;
  }

  return (
    <Box position="absolute" top="0" bottom="0" left="0" right="0" bgcolor="#f4f4f4" fontFamily="'Lato', arial, sans-serif">
      <Stack direction="column" justifyContent="center" alignItems="center" maxWidth="1200px" mt={4} mx="auto" bgcolor="#fff" boxShadow="0 0 4px #ddd">
          <Box width="100%" bgcolor="#3498db">
            <Box fontSize="40px" pl={2} py={2} color="#fff" fontWeight="500">Online Booking</Box>
          </Box>
          <Box width="100%">
            <Box>{children}</Box>
          </Box>
      </Stack>
    </Box>
  )
}
