import { createTheme } from '@mui/material/styles';

export default createTheme({
  palette: {
    primary: {
      light: '#6bccff',
      main: '#249BDB',
      dark: '#006da9',
      contrastText: '#fff',
    },
    secondary: {
      main: '#f44336',
    },
    error: {
      main: '#ff0000',
    },
    warning: {
      main: '#F5A623',
    },
  },
  // typography: {
  //   useNextVariants: true,
  //   fontFamily: 'Roboto, "Helvetica Neue",Helvetica,Arial,sans-serif',
  //   fontSize: '2rem',
  // },
  components: {
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          paddingTop: 5,
          alignItems: 'baseline',
        },
      },
    },
  },
});
