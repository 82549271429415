/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext, useMemo, useState,
} from 'react';

import { useParams } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

export const PaymentContext = createContext();

export function PaymentProvider({ children }) {
  const { token } = useParams();
  const isDesktop = useMediaQuery((t) => t.breakpoints.up('md'));

  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paymentMethodToken, setPaymentMethodToken] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [step, setStep] = useState(0);

  const value = useMemo(() => ({
    invoice,
    isDesktop,
    loading,
    paymentMethodToken,
    paymentMethods,
    step,
    token,
    setInvoice,
    setLoading,
    setStep,
    setPaymentMethodToken,
    setPaymentMethods,
  }), [
    invoice,
    isDesktop,
    loading,
    paymentMethodToken,
    paymentMethods,
    step,
    token,
  ]);

  return (
    <PaymentContext.Provider value={value}>
      {children}
    </PaymentContext.Provider>
  );
}
