// This is V2 of the React Appointment Parser
// I am trying to unify the way data is received by react
// it will replace Appointment/utils/AppointmentParser.js after I modify
// the incoming JSON object

import moment from 'moment';
import 'moment-timezone';

import {
  isConfirmedByDoctible,
  isConfirmedByPractice,
  isConfirmed,
  getApptStatus,
} from './AppointmentStatusV2';

// Data
//
//  appointments: [
//    {
//      id: 'ID of appt',
//      time: 'datetime',
//      confirmed_at: 'datetime / nil',
//      appointment_confirmation_request_confirmed_at: 'datetime / nil',
//      patient_id: 'id of Patient',
//      patient_first_name: 'first name',
//      patient_last_name: 'last name'
//    },
//  ]
//
// time_zone = 'America/Los_Angeles'
//

const sortByTime = (a, b) => a.momentTime - b.momentTime;

const sortAll = (group) => {
  group.sort(sortByTime);
  return group;
};

const sortGroup = (group) => {
  Object.keys(group).forEach((key) => {
    group[key].sort(sortByTime);
  });
  return group;
};

const groupName = (type, appt, timeZone) => {
  const group = {
    default: 'all',
    date: moment(appt.time).tz(timeZone).format('YYYY-MM-DD'),
    patient: appt.patient.id,
  };
  return group[type] || group.default;
};

export default function AppointmentParserV2({
  appts = [],
  timeZone,
}) {
  const pluck = ({
    field = 'id',
  } = {}) => {
    const arr = [];
    appts.forEach((appt) => {
      arr.push(appt[field]);
    });
    return arr;
  };
  const get = ({
    startDate = moment().tz(timeZone),
    plusDaysOut = (365 * 5),
    groupBy,
    confirmed,
    confirmedByDoctible,
    confirmedByPractice,
    nameSearch,
  } = {}) => {
    const startDateMoment = moment(startDate).tz(timeZone);
    const endDateMoment = moment(startDate).tz(timeZone).add(plusDaysOut, 'days');
    const apptsGroup = {};
    appts.forEach((appt) => {
      if (
        (typeof confirmed === 'undefined' || confirmed === isConfirmed(appt))
        && (typeof confirmedByDoctible === 'undefined' || confirmedByDoctible === isConfirmedByDoctible(appt))
        && (typeof confirmedByPractice === 'undefined' || confirmedByPractice === isConfirmedByPractice(appt))
      ) {
        const apptTime = moment(appt.time).tz(timeZone);
        if (
          (typeof nameSearch === 'undefined' || appt.name.toLowerCase().indexOf(nameSearch.toLowerCase()) >= 0)
          && (apptTime.isBetween(startDateMoment, endDateMoment))
        ) {
          const keyName = groupName(groupBy, appt, timeZone);
          if (!apptsGroup[keyName]) {
            apptsGroup[keyName] = [];
          }
          apptsGroup[keyName].push({
            ...appt,
            momentTime: moment.parseZone(appt.time).tz(timeZone),
            ...getApptStatus(appt, timeZone),
          });
        }
      }
    });
    if (typeof groupBy === 'undefined') {
      return (apptsGroup.all) ? sortAll(apptsGroup.all) : [];
    }
    return sortGroup(apptsGroup);
  };
  return {
    pluck,
    get,
    timeZone,
  };
}
