import React from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function Error() {
  return (
          <Box m={5} width="1000px">
            <Alert severity="warning"><Typography fontSize="16px">Oops! Something went wrong.  The page didn't load correctly.</Typography></Alert>
          </Box>
         );
}
