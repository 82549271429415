import AngiesListButtonEn from '../images/angies-list-review-en.svg';
import BbbButtonEn from '../images/bbb-review-en.svg';
import FacebookButtonEn from '../images/facebook-review-en.svg';
import GoogleButtonEn from '../images/google-review-en.svg';
import HealthgradesButtonEn from '../images/healthgrades-review-en.svg';
import RatemdsButtonEn from '../images/ratemds-review-en.svg';
import RealselfButtonEn from '../images/realself-review-en.svg';
import VitalsButtonEn from '../images/vitals-review-en.svg';
import YellowPagesButtonEn from '../images/yellow-pages-review-en.svg';
import YelpButtonEn from '../images/yelp-review-en.svg';
import ZocDocButtonEn from '../images/zoc-doc-review-en.svg';
import HealthyHearingButtonEn from '../images/healthy-hearing-review-en.svg';
import DoctorComButtonEn from '../images/doctor-com-en.svg';

import AngiesListButtonEs from '../images/angies-list-review-es.svg';
import BbbButtonEs from '../images/bbb-review-es.svg';
import FacebookButtonEs from '../images/facebook-review-es.svg';
import GoogleButtonEs from '../images/google-review-es.svg';
import HealthgradesButtonEs from '../images/healthgrades-review-es.svg';
import RatemdsButtonEs from '../images/ratemds-review-es.svg';
import RealselfButtonEs from '../images/realself-review-es.svg';
import VitalsButtonEs from '../images/vitals-review-es.svg';
import YellowPagesButtonEs from '../images/yellow-pages-review-es.svg';
import YelpButtonEs from '../images/yelp-review-es.svg';
import ZocDocButtonEs from '../images/zoc-doc-review-es.svg';
import HealthyHearingButtonEs from '../images/healthy-hearing-review-es.svg';
import DoctorComButtonEs from '../images/doctor-com-es.svg';

export default (locale = 'en') => [
  {
    name: 'Google',
    img: locale === 'es' ? GoogleButtonEs : GoogleButtonEn,
  },
  {
    name: 'Facebook',
    img: locale === 'es' ? FacebookButtonEs : FacebookButtonEn,
  },
  {
    name: 'Yelp',
    img: locale === 'es' ? YelpButtonEs : YelpButtonEn,
  },
  {
    name: "Angie's List",
    img: locale === 'es' ? AngiesListButtonEs : AngiesListButtonEn,
  },
  {
    name: 'Better Business Bureau',
    img: locale === 'es' ? BbbButtonEs : BbbButtonEn,
  },
  {
    name: 'HealthGrades',
    img: locale === 'es' ? HealthgradesButtonEs : HealthgradesButtonEn,
  },
  {
    name: 'RateMDs',
    img: locale === 'es' ? RatemdsButtonEs : RatemdsButtonEn,
  },
  {
    name: 'RealSelf',
    img: locale === 'es' ? RealselfButtonEs : RealselfButtonEn,
  },
  {
    name: 'Vitals',
    img: locale === 'es' ? VitalsButtonEs : VitalsButtonEn,
  },
  {
    name: 'Yellow Pages',
    img: locale === 'es' ? YellowPagesButtonEs : YellowPagesButtonEn,
  },
  {
    name: 'Zocdoc',
    img: locale === 'es' ? ZocDocButtonEs : ZocDocButtonEn,
  },
  {
    name: 'Healthy Hearing',
    img: locale === 'es' ? HealthyHearingButtonEs : HealthyHearingButtonEn,
  },
  {
    name: 'Doctor.Com',
    img: locale === 'es' ? DoctorComButtonEs : DoctorComButtonEn,
  },
];
