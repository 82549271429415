import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import ReviewLinksGreen from './ReviewLinksGreen';
import Localize from '../context/Localize';

const styles = theme => ({
  feedbackButton: {
    width: '94%',
    height: '50px',
    marginTop: '5px',
    textTransform: 'none',
    fontSize: '18px',
    backgroundColor: '#e53935',
    boxShadow: 'none',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#EE494E',
    },
    '&:focused': {
      backgroundColor: '#EE494E',
    },
    '&:active': {
      backgroundColor: '#EE494E',
    },
    '&:visited': {
      backgroundColor: '#EE494E',
    },
    [theme.breakpoints.up('md')]: {
      width: '90%',
      height: '73px',
      fontSize: '25px',
    },
  },
});

function NoFilterReviewCustomText(props) {
  const { classes } = props;
  function contactUs() {
    props.history.push(`${props.location.pathname}/feedback`);
  }
  function ReviewSiteLinks() {
    return (
      <ReviewLinksGreen
        locale={props.locale}
        availableReviewSites={props.availableReviewSites}
        recordReviewSiteClick={!props.universal ? props.recordReviewSiteClick : () => {}}
      />
    );
  }
  function FeedbackButton() {
    return (
      <Localize.Consumer>
        {(t = {}) => (
          <Button
            variant="contained"
            classes={{ root: classes.feedbackButton }}
            onClick={contactUs}
            data-testid="filter-feedback-button"
          >
          {/*i18n-tasks-use t('base_react.no_filter_review_request.contact_us_directly')*/}
            {t.contact_us_directly}
          </Button>
        )}
      </Localize.Consumer>
    );
  }
  const displayContent = props.contentBody.split(/({{.*?}})|(<br>)/).map(text => {
    if (text === "{{review_site_buttons}}") return ReviewSiteLinks();
    if (text === "{{feedback_button}}") return FeedbackButton();
    if (text === "<br>") return (<br />);
    if (text === "" || text === undefined) return null;

    return <Box fontSize="0.8em" py="12px" whiteSpace="pre-line">{text}</Box>;
  });

  return (
    <Box textAlign="center">
      <Box fontSize="1.2em" fontWeight="bold" py="12px">{props.contentTitle}</Box>
      {displayContent.map((row, i) => <React.Fragment key={i}>{row}</React.Fragment>)}
    </Box>
  )
}

export default withStyles(styles)(withRouter(NoFilterReviewCustomText));
