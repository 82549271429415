import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';

const styles = {
  successMessage: {
    textAlign: 'center',
    marginBottom: '20px',
    width: '310px',
    height: '50px',
    lineHeight: '50px',
    color: '#ffffff',
    backgroundColor: '#BCDA90',
  },
  errorMessage: {
    textAlign: 'center',
    marginBottom: '20px',
    width: '310px',
    height: '50px',
    lineHeight: '50px',
    color: '#ffffff',
    backgroundColor: '#E10050',
  },
};

class AutoHideMessage extends React.Component {
  static propTypes = {
    message: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    hideMessage: PropTypes.func.isRequired, // eslint-disable-line react/forbid-prop-types
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    stateReset: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  }

  constructor(props) {
    super(props);
    this.hideMessage();
  }

  componentWillUnmount() {
    if (this.timerHideMessage) {
      clearInterval(this.timerHideMessage);
    }
  }

  hideMessage = () => {
    this.timerHideMessage = setTimeout(
      () => {
        this.props.hideMessage(this.props.stateReset);
      },
      10000,
    );
    return true;
  };

  render() {
    return (
      <div className={
        classNames({
            [this.props.classes.successMessage]: this.props.type === 'success',
            [this.props.classes.errorMessage]: this.props.type === 'error',
          })
        }
      >
        {this.props.message}
      </div>
    );
  }
}

export default withStyles(styles)(AutoHideMessage);
