import React from 'react';
import { TextField } from '@material-ui/core';
import { IMaskMixin } from 'react-imask';

const MaskedTextField = IMaskMixin(
  ({ inputRef, defaultValue, ...otherProps }) => (
    <TextField {...otherProps} inputRef={inputRef} defaultValue={defaultValue} InputLabelProps={{ shrink: true }} />
  ),
);

function MaskedField({
  name, label, value, setValue, ...otherProps
}) {
  return (
    <MaskedTextField
      id={name}
      name={name}
      label={label}
      value={value}
      onAccept={(value) => {
			  setValue(value);
      }}
      {...otherProps}
    />
  );
}

export { MaskedField };
