/* eslint-disable max-len */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

export default function MultiPatientRequests({
  patientsToConfirm, fastFill, easyFillRequest, handleCodeConfirmationToggle,
}) {
  const efRequestPatients = easyFillRequest.patientsWithSameContact || [];

  if (efRequestPatients.length === 0) return <></>; // no "active" patients for this request

  return (
    <>
      <Typography variant="subtitle1" align="center" style={{ marginBottom: 16 }}>
        {easyFillRequest.weekday}
        ,
        {' '}
        {easyFillRequest.formattedDate}
        {' '}
        at
        {' '}
        {easyFillRequest.formattedTime}
      </Typography>
      { efRequestPatients?.map((easyFillRequestPatient) => (
        <Box key={easyFillRequestPatient.id} display="flex" justifyContent="space-between" alignItems="center" width="100%" mb={2}>
          <Tooltip
            title="Confirm availability"
            placement="top"
            disableInteractive
          >
            <FormControlLabel
              control={fastFill
                ? (
                  <Radio
                    checked={patientsToConfirm.findIndex((patientToConfirm) => patientToConfirm.code === easyFillRequestPatient.code) !== -1}
                    onChange={() => handleCodeConfirmationToggle(easyFillRequestPatient)}
                  />
                ) : (
                  <Checkbox
                    checked={patientsToConfirm.findIndex((patientToConfirm) => patientToConfirm.code === easyFillRequestPatient.code) !== -1 || easyFillRequestPatient.status === 'confirmed_availability'}
                    disabled={easyFillRequestPatient.status === 'confirmed_availability'}
                    onChange={() => handleCodeConfirmationToggle(easyFillRequestPatient)}
                  />
                )}
              label={(
                <Typography fontSize={18} width="100%" textAlign="center">
                  {easyFillRequestPatient.patient.fullName}
                </Typography>
              )}
              sx={{ width: '100%' }}
            />
          </Tooltip>
        </Box>
      ))}
    </>
  );
}
