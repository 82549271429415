import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CssBaseline from '@mui/material/CssBaseline';
import makeStyles from '@mui/styles/makeStyles';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { green } from '@mui/material/colors';

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
  },
  img: {
    maxHeight: '100%',
    maxWidth: '100%',
  },
}));

export default function FormCompleted() {
  const classes = useStyles();

  const elHtml = document.getElementById('reactData').innerHTML;

  let data = {};
  if (elHtml) {
    data = JSON.parse(elHtml);
  }

  return (
    <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" mt={2} mx="auto" width="500px">
      <CssBaseline />
      {data.logo_url && (
      <Box width="200px" height="60px" display="inline-block" mb={2} textAlign="center">
        <img
          className={classes.img}
          src={data.logo_url}
          alt={data.name}
        />
      </Box>
          )}
      <Paper
        classes={{ root: classes.paper }}
        elevation={4}
      >
        <Box p={3} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
          <Box color={green[600]}>
            <CheckCircle style={{ width: '90px', height: '90px' }} />
          </Box>

          <Box mt={1}>Thank You!</Box>
          <Box fontSize="0.8rem" mt={0.5}>Your submission has been received.</Box>
          <Box fontSize="0.7rem" mt={5}>{data.name}</Box>
          <Box fontSize="0.7rem" mt={0.25}>{data.phone}</Box>
        </Box>
      </Paper>
    </Box>
  );
}
