/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useState } from 'react';
import { Formik, Form, Field } from 'formik';

import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import useRequest from '@shared/services/hooks/useRequest';
import { PaymentContext } from '../../context/PaymentContext';
import './styles.css';

export default function PatientVerification() {
  const {
    token, setInvoice, setPaymentMethodToken, setStep,
  } = useContext(PaymentContext);
  const { request } = useRequest();

  const [error, setError] = useState(null);

  const getInvoice = async (values, { setErrors }) => {
    const response = await request({
      url: `/payments/${token}.json?last_name=${values.last_name}&birth_date=${values.birth_date}`,
      method: 'GET',
    });
    if (response.error) {
      setError(response.error);
      if (response.errors) {
        setErrors({ last_name: response.errors.last_name, birth_date: response.errors.birth_date });
      }
      return;
    }
    setInvoice(response.invoice);
    setPaymentMethodToken(response.invoice?.paymentMethodToken);
    if (Number.parseInt(response.invoice.amountDue, 10) === 0) {
      setStep(2);
      return;
    }

    setStep(1);
    setError(null);
  };

  return (
    <Box sx={{
      display: 'grid', placeItems: 'center', width: '100%', height: '64dvh',
    }}
    >
      <Box sx={{ width: '100%' }}>
        <Typography variant="h4" sx={{ textAlign: 'center', marginBottom: '1rem' }}>
          Make a Payment
        </Typography>
        <Typography gutterBottom>
          Please verify your identity
        </Typography>
        <Formik
          initialValues={{
            last_name: '',
            birth_date: null,
          }}
          onSubmit={getInvoice}
        >
          {({ isSubmitting, errors }) => (
            <Form>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Field name="last_name" type="text">
                  {({ field }) => (
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        {...field}
                        disabled={isSubmitting}
                        variant="outlined"
                        label="Last Name"
                        margin="normal"
                        error={errors.last_name?.length > 0}
                        fullWidth
                      />
                    </FormControl>
                  )}
                </Field>
                <Field name="birth_date" type="date">
                  {({ field }) => (
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        {...field}
                        type="date"
                        disabled={isSubmitting}
                        variant="outlined"
                        label="Date of Birth"
                        error={errors.birth_date?.length > 0}
                        InputLabelProps={{ shrink: true }}
                        inputProps={
                          {
                            max: new Date().toISOString().split('T')[0],
                            sx: (theme) => (
                              {
                                color: field.value
                                  ? theme.palette.text.primary
                                  : theme.palette.grey[700],
                              }
                            ),
                          }
                        }
                        fullWidth
                      />
                    </FormControl>
                  )}
                </Field>
                {error && <Typography variant="body1" color="error">{error}</Typography>}
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={isSubmitting}
                  fullWidth
                >
                  Verify
                </LoadingButton>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}
