import React from 'react';

export default function TestScript({ intakeqId }) {
  return (
    <React.Fragment>
      {
        (
          function (c) {
            window.providerScheduleLocation="harland-brewing-one-paseo-san-diego-ca";
            var i = c.createElement("script");
            i.type = "text/javascript";
            i.async = true;
            i.src = "http://localhost:3000/v1/practice-schedule.js";
            document.head.appendChild(i);
          }
        )(document)
      }
      <div id="smartSchedulerHtml"></div>
    </React.Fragment>
  )
}