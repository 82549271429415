import React, { useContext, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import AddCardIcon from '@mui/icons-material/AddCard';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CreditScoreIcon from '@mui/icons-material/CreditScore';

import useRequest from '@shared/services/hooks/useRequest';

import IframeCreditCardTokenizer from './IframeCreditCardTokenizer';
import { PaymentContext } from '../../context/PaymentContext';

const styles = {
  paymentMethod: {
    flexDirection: 'column', alignItems: 'flex-start', width: '100%', p: 0, m: 0, cursor: 'pointer',
  },
  paymentMethodButton: {
    width: '100%',
  },
  paymentMethodDropdown: {
    width: '100%',
    padding: '16px',
  },
  paymentMethodOpened: {
    border: '1px solid #b8bdc0',
    borderRadius: '4px',
  },
  paymentMethodSelected: {
    border: '1px solid #2499d6',
    borderRadius: '4px',
    fontWeight: 500,
    fontSize: '1.25rem',
  },
};

export default function PaymentMethod() {
  const {
    invoice, token: shortCode, paymentMethods, paymentMethodToken, setPaymentMethods, setPaymentMethodToken,
  } = useContext(PaymentContext);
  const { request } = useRequest();
  const [tempPaymentMethod, setTempPaymentMethod] = useState(null);
  const [openedPaymentMethod, setOpenedPaymentMethod] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const [saveForFuture, setSaveForFuture] = useState(false);
  const paymentMethodsPath = `/payments/${shortCode}/payment_methods`;
  const isPaymentPlan = invoice.invoiceType !== 'single_pay';

  const handlePaymentMethodTokenChange = (token) => {
    setPaymentMethodToken(token);
    setOpenedPaymentMethod(null);
  };

  const handleOpenAddCard = () => {
    setOpenedPaymentMethod('new');
    setPaymentMethodToken(null);
  };

  const getPaymentMethods = async () => {
    const response = await request({
      url: `${paymentMethodsPath}.json`,
      method: 'GET',
    });
    if (response.error) {
      return;
    }
    if (response.paymentMethods) {
      setPaymentMethods(response.paymentMethods);
    }
  };

  const deletePaymentMethod = async (id) => {
    const response = await request({
      url: `${paymentMethodsPath}/${id}.json`,
      method: 'DELETE',
    });
    if (response.error) {
      return;
    }
    setPaymentMethods(response.paymentMethods);
  };

  const processNewToken = async (paymentMethod) => {
    const { token, expiration } = paymentMethod;
    const response = await request({
      url: `${paymentMethodsPath}.json`,
      method: 'POST',
      data: {
        payment_method: {
          token,
          expiration,
          saved_for_future_use: isPaymentPlan || saveForFuture,
        },
      },
    });
    if (response.error) {
      return;
    }
    setPaymentMethods(response.paymentMethods);
    handlePaymentMethodTokenChange(token);
  };

  React.useEffect(() => {
    getPaymentMethods();
  }, []);

  return (
    <Box>
      <Typography variant="h6">
        Pay with
      </Typography>
      <List sx={{ p: 0, m: 0, mt: 1 }}>
        { paymentMethods.map((paymentMethod) => (
          <ListItem
            key={paymentMethod.token}
            sx={{
              ...styles.paymentMethod,
              ...(openedPaymentMethod === paymentMethod.token ? styles.paymentMethodOpened : {}),
              ...(paymentMethodToken === paymentMethod.token ? styles.paymentMethodSelected : {}),
            }}
          >
            <ListItemButton
              sx={styles.paymentMethodButton}
              onClick={() => (
                setOpenedPaymentMethod((v) => (
                  v === paymentMethod.token ? null : paymentMethod.token
                ))
              )}
            >
              <ListItemIcon>
                { paymentMethodToken === paymentMethod.token ? (
                  <CreditScoreIcon color="primary" />
                ) : (
                  <CreditCardIcon />
                ) }
              </ListItemIcon>
              <ListItemText primary={<Typography variant="h6">{paymentMethod.cardPreview}</Typography>} />
            </ListItemButton>
            { openedPaymentMethod === paymentMethod.token && (
              <Box sx={{
                ...styles.paymentMethodDropdown, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: 'calc(100% - 32px)',
              }}
              >
                <Button color="inherit" onClick={() => deletePaymentMethod(paymentMethod.id)}>Remove</Button>
                <Button color="primary" variant="contained" onClick={() => handlePaymentMethodTokenChange(paymentMethod.token)}>Use this card</Button>
              </Box>
            )}
          </ListItem>
        ))}
        <ListItem disablePadding sx={{ ...styles.paymentMethod, ...(openedPaymentMethod === 'new' ? styles.paymentMethodOpened : {}) }}>
          <ListItemButton sx={styles.paymentMethodButton} onClick={handleOpenAddCard}>
            <ListItemIcon>
              <AddCardIcon />
            </ListItemIcon>
            <ListItemText primary={<Typography variant="h6">Add new card</Typography>} />
          </ListItemButton>
          { openedPaymentMethod === 'new' && (
            <Box sx={styles.paymentMethodDropdown}>
              <IframeCreditCardTokenizer
                processToken={(token, expiration, error) => {
                  if (!token) {
                    setTempPaymentMethod(null);
                    setValidationError(error);
                    return;
                  }
                  setTempPaymentMethod({ token, expiration });
                  setValidationError(null);
                }}
              />

              { validationError && (
                <Typography variant="body2" color="error">
                  {validationError}
                </Typography>
              )}

              <FormGroup sx={{ mb: 2 }}>
                <Tooltip
                  title={isPaymentPlan && 'Cards for Payment Plans are saved automatically'}
                  placement="top-start"
                  disableInteractive
                >
                  <FormControlLabel
                    disabled={isPaymentPlan}
                    control={(
                      <Checkbox
                        checked={isPaymentPlan || saveForFuture}
                        onChange={(event) => setSaveForFuture(event.target.checked)}
                      />
                    )}
                    label="Save for future use"
                  />
                </Tooltip>
                { (isPaymentPlan || saveForFuture) && (
                  <Typography variant="body2" color="textSecondary">
                    We may perform a $0 transaction for account verification.
                  </Typography>
                )}
              </FormGroup>
              <Box sx={{
                display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%',
              }}
              >
                <Button color="inherit" onClick={() => setOpenedPaymentMethod(null)}>Cancel</Button>
                <Button
                  variant="outlined"
                  disabled={!tempPaymentMethod}
                  onClick={() => { processNewToken(tempPaymentMethod); }}
                >
                  Use this card
                </Button>
              </Box>
            </Box>
          )}
        </ListItem>
      </List>
    </Box>
  );
}
