import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';

import FilterExperience from './components/FilterExperience';
import NoFilterExperience from './components/NoFilterExperience';
import AlignCenter from '../../components/AlignCenter';
import Wrapper from './components/Wrapper';
import ContentWrapper from './components/ContentWrapper';
import Footer from './components/Footer';
import Localize from './context/Localize';

class ReviewRequests extends React.Component {
  constructor(props) {
    super(props);
    const data = JSON.parse(document.getElementById('reactData').innerHTML);

    this.state = {
      t: data.t,
      location: data.location,
      showLogo: data.show_logo,
      showLocationName: data.show_location_name,
    };
  }

  render() {
    return (
      <Localize.Provider value={this.state.t}>
        <Wrapper>
          <CssBaseline />
          <AlignCenter>
            <ContentWrapper location={this.state.location} showLogo={this.state.showLogo} showLocationName={this.state.showLocationName}>
              {!this.state.location.review_request_filter && <NoFilterExperience />}
              {this.state.location.review_request_filter && <FilterExperience />}
            </ContentWrapper>
            <Footer />
          </AlignCenter>
        </Wrapper>
      </Localize.Provider>
    );
  }
}

export default ReviewRequests;
