import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import request from '@shared/services/api/requests';
import FormButton from './FormButton';
import Localize from '../context/Localize';

const styles = theme => ({
  wrapper: {
    textAlign: 'center',
  },
  headlineText: {
    fontSize: '17px',
    fontWeight: '500',
    padding: '25px 0 10px 0',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('md')]: {
      fontSize: '31px',
      padding: '25px 0 20px 0',
    },
  },
  headlineTextNoFilter: {
    fontSize: '16px',
    fontWeight: '500',
    padding: '15px 0 10px 0',
    [theme.breakpoints.up('md')]: {
      fontSize: '22px',
      lineHeight: '28px',
      padding: '25px 0 20px 0',
    },
  },
  label: {
    display: 'block',
    textAlign: 'left',
    fontSize: '16px',
    fontWeight: '500',
  },
  backButtonWrapper: {
    textAlign: 'left',
    marginLeft: '-10px',
  },
  backButton: {
    textTransform: 'none',
    color: '#3678BD',
    padding: '0 10px 0 0',
    fontSize: '14px',
    minWidth: '0',
    minHeight: '0',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
    },
  },
  inputFields: {
    fontSize: '14px',
  },
  textField: {
    border: '1px solid #dddddd',
    marginTop: '3px',
    marginBottom: '7px',
    padding: '4px 10px',
    fontSize: '14px',
  },
  publishDisclosure: {
    marginLeft: '2px',
    fontSize: '10px',
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      fontSize: '12px',
    },
  },
});

export class FeedbackForm extends React.Component {
  static propTypes = {
    token: PropTypes.string,
    universal: PropTypes.bool,
    starState: PropTypes.number.isRequired,
    isFilterExperience: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    backButton: PropTypes.func.isRequired, // eslint-disable-line react/forbid-prop-types
    submit: PropTypes.func.isRequired, // eslint-disable-line react/forbid-prop-types
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };
  static defaultProps = {
    universal: false,
    token: null,
  };

  state = {
    feedback: '',
    name: '',
    email: '',
  };

  componentDidMount() {
    if (this.props.token) {
      const token = this.props.token;
      request({
        url: `/api/review_requests/${token}`,
        method: 'PATCH',
      });
    }
  }

  handleChange = name => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  isEmailValidate = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
    return re.test(String(email).toLowerCase());
  };

  isFormValid = () => {
    if (this.props.universal) {
      if (this.state.name.length === 0) {
        return false;
      }
      if (this.state.email.length === 0) {
        return false;
      } else if (!this.isEmailValidate(this.state.email)) {
        return false;
      }
    }
    if (this.state.feedback.length === 0) {
      return false;
    }
    return true;
  };

  submit = () => {
    this.props.submit({
      message: this.state.feedback,
      name: this.state.name,
      email: this.state.email,
    });
  };

  render() {
    const { classes, isFilterExperience } = this.props;
    return (
      <Localize.Consumer>
        {(t = {}) => (
          <div className={classes.wrapper}>
            <div className={classes.backButtonWrapper}>
              <Button
                className={classes.backButton}
                onClick={this.props.backButton}
                data-testid="feedback-back-button"
              >
                <KeyboardArrowLeftIcon />
                {/* i18n-tasks-use t('base_react.no_filter_review_request.go_back') */}
                {t.go_back}
              </Button>
            </div>
            {isFilterExperience ? (
              <div className={classes.headlineText}>
                {t[`feedback_headline_message_${this.props.starState > 3 ? 'good' : 'bad'}`]}
              </div>
            ) : (
              <div className={classes.headlineTextNoFilter}>
              {/* i18n-tasks-use t('base_react.no_filter_review_request.feedback_headline_message_bad') */}
              {t.feedback_headline_message_bad}
              </div>
            )}
            {this.props.universal && (
              <label className={classes.label} htmlFor="name">
                Name*
                <TextField
                  autoComplete="off"
                  error
                  fullWidth
                  id="name"
                  value={this.state.name}
                  onChange={this.handleChange('name')}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.inputFields,
                    },
                  }}
                  className={classes.textField}
                  margin="none"
                  variant="standard"
                />
              </label>
            )}
            {this.props.universal && (
              <label className={classes.label} htmlFor="email">
                Email*
                <TextField
                  autoComplete="off"
                  fullWidth
                  id="email"
                  value={this.state.email}
                  onChange={this.handleChange('email')}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.inputFields,
                    },
                  }}
                  className={classes.textField}
                  margin="none"
                  variant="standard"
                />
              </label>
            )}
            <label className={classes.label} htmlFor="feedback">
              {/* i18n-tasks-use t('base_react.no_filter_review_request.feedback') */}
              {this.props.universal ? `${t.feedback}*` : ''}
              {/* i18n-tasks-use t('base_react.review_requests.type_something') */}
              <TextField
                autoFocus
                multiline
                fullWidth
                rows="5"
                placeholder={this.props.universal ? '' : t.type_something}
                value={this.state.feedback}
                onChange={this.handleChange('feedback')}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    inputMultiline: classes.inputFields,
                  },
                }}
                className={classes.textField}
                margin="none"
                variant="standard"
              />
            </label>
            {this.props.starState > 3 && (
              <div className={classes.publishDisclosure}>
              {/* i18n-tasks-use t('base_react.review_requests.share_review_warning') */}
              {t.share_review_warning}
              </div>
            )}
            {/* i18n-tasks-use t('base_react.review_requests.submit') */}
            <FormButton
              text={t.submit}
              submit={this.submit}
              disabled={!this.isFormValid() || this.props.isSubmitting}
              isSubmitting={this.props.isSubmitting}
            />
          </div>
        )}
      </Localize.Consumer>
    );
  }
}

export default withStyles(styles)(FeedbackForm);
