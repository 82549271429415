import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useLocation } from 'react-router-dom';

import request from '@shared/services/api/requests';

const innerTheme = createTheme({
  typography: {
    fontSize: 14,
  },
});

const useStyles = makeStyles(() => ({
  radioSelected: {
    border: '1px solid #249BDB',
  },
  radioDefault: {
    border: '1px solid #D7EDF8',
  },
}));

const BlueRadio = withStyles({
  root: {
    '&$checked': {
      color: '#249BDB',
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />);

const BlueCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#249BDB',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

export default function Survey() {
  const location = useLocation();
  const classes = useStyles();
  const reactData = JSON.parse(document.getElementById('reactData').innerHTML);
  const { contact, location: currentLocation, consent } = reactData;
  const questions = reactData.surveyQuestions;
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [givesConsent, setGivesConsent] = React.useState(false);
  const [values, setValues] = React.useState(Object.keys(questions).reduce(
    (questionMap, key) => ({ ...questionMap, [key]: null }),
    {},
  ));
  const [completed, setCompleted] = React.useState(reactData.completed);

  async function submitFom() {
    setIsSubmitting(true);
    const response = await request({
      url: `${location.pathname}`,
      method: 'POST',
      data: {
        vwr: {
          contact_id: contact.id,
          survey: values,
        },
      },
    });
    if (!response.error) {
      setCompleted(true);
    }
    setIsSubmitting(false);
    return true;
  }

  function validForm() {
    let isValid = true;
    Object.keys(questions).forEach((key) => {
      if (!values[key]) {
        isValid = false;
      }
    });
    if (consent.required && !givesConsent) {
      isValid = false;
    }
    return isValid;
  }

  if (completed) {
    return (
      <Box
        width="310px"
        maxWidth="310px"
        my={2}
        mx="auto"
        fontFamily='"Roboto", "Helvetica", "Arial", sans-serif'
      >
        <Paper elevation={3}>
          <Box display="flex" p={3} bgcolor="">
            {`Thank you for completing the form. ${currentLocation.name} will message you shortly`}
          </Box>
        </Paper>
      </Box>
    );
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={innerTheme}>
        <Box
          width="310px"
          maxWidth="310px"
          my={2}
          mx="auto"
          fontFamily='"Roboto", "Helvetica", "Arial", sans-serif'
        >
          <Paper elevation={3}>
            <Box display="flex" flexDirection="column">
              <Box flex={1} py={3} px={2} bgcolor="#249BDB" color="#ffffff">
                <Box fontSize="15px" pb={2}>
                  {currentLocation.name}
                </Box>
                <Box fontSize="20px">Health Screening</Box>
              </Box>
              <Box flex={1} p={3}>
                <Box>
                  If you have been exposed to a communicable disease, you may
                  spread the disease to the doctor, staff, or other patients in
                  the practice. Therefore, prior to your appointment, we will be
                  asking the following questions to reduce the chances of
                  transmission.
                </Box>
              </Box>
              {Object.keys(questions).map((key, index) => (
                <Box key={key} display="flex" m={2}>
                  <Box width="20px" textAlign="right" pr={1}>
                    {index + 1}.
                  </Box>
                  <Box flex={1}>
                    <Box mb={1}>{questions[key]}</Box>
                    <Box>
                      <RadioGroup
                        aria-label="gender"
                        name={key}
                        value={values[key]}
                        onChange={event =>
                          setValues({
                            ...values,
                            [key]: event.target.value,
                          })
                        }
                      >
                        <Box
                          display="flex"
                          flexDirection="column"
                          borderRadius="4px"
                          px={1}
                          className={
                            values[questions[key]] === 'no'
                              ? classes.radioSelected
                              : classes.radioDefault
                          }
                        >
                          <FormControlLabel
                            value="no"
                            control={<BlueRadio />}
                            label="No"
                          />
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          borderRadius="4px"
                          mb={0.5}
                          px={1}
                          className={
                            values[questions[key]] === 'yes'
                              ? classes.radioSelected
                              : classes.radioDefault
                          }
                        >
                          <FormControlLabel
                            value="yes"
                            control={<BlueRadio />}
                            label="Yes"
                          />
                        </Box>
                      </RadioGroup>
                    </Box>
                  </Box>
                </Box>
              ))}
              {consent.required && (
              <Box flex={1} p={3}>
                <Box p={1} height="50px" border="1px solid #ddd" fontSize="0.8rem" style={{ overflowY: 'auto' }}>
                  {consent.text}
                </Box>
                <Box>
                  <FormControlLabel
                    control={
                      <BlueCheckbox
                        checked={givesConsent}
                        onChange={event => setGivesConsent(event.target.checked)}
                        name="checkedB"
                        color="primary"
                      />
                  }
                    label={<Box mt={2.5} fontSize="0.9rem">I acknowledge the risk and consent to treatment</Box>}
                  />
                </Box>
              </Box>
              )}
              <Box m={2}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={submitFom}
                  disabled={!validForm() || isSubmitting}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Paper>
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
