import React, { useContext } from 'react';

import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import currentLocation from '@practice_users/services/locationHelper';
import PaymentAuthorized from './PaymentAuthorized';
import PaymentConfirmation from './PaymentConfirmation';
import PatientVerification from './PatientVerification';
import Invoice from './Invoice';

import { PaymentContext } from '../context/PaymentContext';

export default function View() {
  const { step, loading, invoice } = useContext(PaymentContext);

  if (invoice?.status === 'cancelled') {
    return (
      <Box sx={{
        display: 'grid', placeItems: 'center', width: '100%', height: '64dvh',
      }}
      >
        <Box sx={{ width: '100%' }}>
          <Typography variant="h4" sx={{ textAlign: 'center', marginBottom: '1rem' }}>
            This invoice has been cancelled by the practice.
          </Typography>
          <Typography gutterBottom textAlign="center">
            Please contact
            {' '}
            { currentLocation.name }
            {' '}
            for more information in case you have questions.
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <>
      { loading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="primary" />
        </Backdrop>
      ) }
      <Box sx={{ mb: 4 }}>
        {step === 0 && <PatientVerification />}
        {step === 1 && <PaymentConfirmation />}
        {step === 2 && <PaymentAuthorized />}
        {step === 3 && <Invoice />}
      </Box>
    </>
  );
}
