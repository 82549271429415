import React from 'react';
import ReactDOM from 'react-dom';
import { Clearfix, Row, Col, Form } from 'react-bootstrap';
import { HeaderText, FormWrapper, ColCenter, FormLabel, FormGroupWrapper, FormControlInput, FormButton, FooterText } from './Styles';

class BirthdayVerification extends React.PureComponent {
  constructor(props) {
    super(props);
    this.dateNow = new Date().getFullYear();
    this.dateMinus120 = new Date(new Date().setFullYear(new Date().getFullYear() - 120)).getFullYear();
    this.daysInMonth = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    this.state = {
      dobMm: '',
      dobDd: '',
      dobYy: '',
      validateDobMm: null,
      validateDobDd: null,
      validateDobYy: null,
    };
  }

  getValidationState = (field, length, small, large) => {
    if (field.length !== length) return 'error';
    const fieldNumber = parseFloat(field);
    if (fieldNumber < small || fieldNumber > large) return 'error';
    const dobMm = parseFloat(this.state.dobMm);
    if (this.state.validateDobMm === null && large === 31 && dobMm >= 1 && dobMm <= 12 && fieldNumber > this.daysInMonth[(dobMm - 1)]) return 'error';
    return null;
  }

  formSubmit = (e) => {
    e.preventDefault();
    const validateDobMm = this.getValidationState(this.state.dobMm, 2, 1, 12);
    const validateDobDd = this.getValidationState(this.state.dobDd, 2, 1, 31);
    const validateDobYy = this.getValidationState(this.state.dobYy, 4, this.dateMinus120, this.dateNow);
    this.setState({
      validateDobMm,
      validateDobDd,
      validateDobYy,
    });
    if (!!validateDobMm || !!validateDobDd || !!validateDobYy) {
      return false;
    }
    const { match: { params: { id } } } = this.props;
    window.location.replace(`/payment/${id}`);
  }

  monthChange = (e) => {
    const n = e.target.value.replace(/\D/g, '');
    if (n.length <= 2) {
      this.setState({ dobMm: n });
    }
    if (n.length === 2) {
      ReactDOM.findDOMNode(this.dayInput).focus();
    }
    if (this.state.validateDobMm) {
      this.setState({ validateDobMm: this.getValidationState(n, 2, 1, 12) });
    }
  }

  onMonthBlur = (e) => {
    const n = e.target.value.replace(/\D/g, '')
    if (n.length == 1 && n >= 1 && n <= 9) {
      const leading0 = "0" + n.toString()
      this.setState({ dobMm: leading0 });
      this.setState({ validateDobMm: this.getValidationState(leading0, 2, 1, 12) });
    }
  }

  dayChange = (e) => {
    const n = e.target.value.replace(/\D/g, '')
    if (n.length <= 2) {
      this.setState({ dobDd: n });
    }
    if (n.length === 2) {
      ReactDOM.findDOMNode(this.yearInput).focus();
    }
    if (this.state.validateDobDd) {
      this.setState({ validateDobDd: this.getValidationState(n, 2, 1, 31) });
    }
  }

  onDayBlur = (e) => {
    const n = e.target.value.replace(/\D/g, '');
    if (n.length === 1 && n >= 1 && n <= 9) {
      const leading0 = `0${n.toString()}`;
      this.setState({ dobDd: leading0 });
      this.setState({ validateDobDd: this.getValidationState(leading0, 2, 1, 31) });
    }
  }

  yearChange = (e) => {
    const n = e.target.value.replace(/\D/g, '');
    if (n.length <= 4) {
      this.setState({ dobYy: n });
    }
    if (this.state.validateDobYy) {
      this.setState({ validateDobYy: this.getValidationState(n, 4, this.dateMinus120, this.dateNow) });
    }
  }

  render() {
    return (
      <Clearfix>
        <Row className="show-grid">
          <ColCenter xs={10} xsOffset={1} md={8} mdOffset={2} lg={6} lgOffset={3}>
            <HeaderText>Please Enter Your Date of Birth to Confirm Your Identity</HeaderText>
            <Form inline onSubmit={this.formSubmit} method="POST">
              <input name="utf8" type="hidden" value="&#x2713;" />
              <input type="hidden" name="_method" value="patch" />
              <FormWrapper>
                <FormLabel>Date of Birth</FormLabel>
                <FormGroupWrapper controlId="formMM" validationState={this.state.validateDobMm} >
                  <FormControlInput
                    name="month"
                    type="text"
                    value={this.state.dobMm}
                    placeholder="MM"
                    onChange={this.monthChange}
                    onBlur={this.onMonthBlur}
                    ref={(input) => { this.monthInput = input; }}
                    error={!!this.state.validateDobMm}
                    autoComplete="off" />
                </FormGroupWrapper>
                <FormGroupWrapper controlId="formDD" validationState={this.state.validateDobDd} >
                  <FormControlInput
                    name="day"
                    type="text"
                    value={this.state.dobDd}
                    placeholder="DD"
                    onChange={this.dayChange}
                    onBlur={this.onDayBlur}
                    ref={(input) => { this.dayInput = input; }}
                    error={!!this.state.validateDobDd}
                    autoComplete="off" />
                </FormGroupWrapper>
                <FormGroupWrapper margin="0" controlId="formYY" validationState={this.state.validateDobYy} >
                  <FormControlInput
                    name="year"
                    type="text"
                    value={this.state.dobYy}
                    placeholder="YYYY"
                    onChange={this.yearChange}
                    ref={(input) => { this.yearInput = input; }}
                    error={!!this.state.validateDobYy}
                    autoComplete="off" />
                </FormGroupWrapper>
                <FormGroupWrapper>
                  <FormButton type="submit">Confirm and View Invoice</FormButton>
                </FormGroupWrapper>
              </FormWrapper>
            </Form>
          </ColCenter>
        </Row>
        <Row className="show-grid">
          <Col xs={10} xsOffset={1}>
            <FooterText></FooterText>
          </Col>
        </Row>
      </Clearfix>
    );
  }
}

export default BirthdayVerification;
