import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import './styles.css';

// Icons borrowed from https://codepen.io/jagathish/pen/dBLWPK
function SuccessIcon() {
  return (
    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
      <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
      <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </svg>
  );
}

function ErrorIcon() {
  return (
    <svg className="crossmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
      <circle className="crossmark__circle" cx="26" cy="26" r="25" fill="none" />
      <path className="cross__path cross__path--right" fill="none" d="M16,16 l20,20" />
      <path className="cross__path cross__path--left" fill="none" d="M16,36 l20,-20" />
    </svg>
  );
}

export default function PaymentResponse({ type, message }) {
  return (
    <Grid item xs={12} md={8} textAlign="center" mt={4}>
      { type === 'success' && <SuccessIcon /> }
      { type === 'error' && <ErrorIcon /> }
      { message && (
        <Typography variant="body1">
          {message}
        </Typography>
      ) }
    </Grid>
  );
}
