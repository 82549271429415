import React, { useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import moment from 'moment';
import 'moment-timezone';
import TextField from '@mui/material/TextField';
import { Controller, useForm } from "react-hook-form";
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import request from '@shared/services/api/requests';
import DateSelector from './DateSelector'


export default function CsdValidation({ data }) {
  const [beginningDate, setBeginningDate] = React.useState(moment().subtract(1, 'day').toDate());
  const [endDate, setEndDate] = React.useState(moment().add(3, 'day').toDate());
  const locationParams = useLocation();
  const { register, handleSubmit, control, errors, reset } = useForm({ defaultValues: {
    csdId: '',
    dmpsId: '',
    locationId: '',
  }});
  const { enqueueSnackbar } = useSnackbar();

  async function onSubmit(formData) {
    const beginningDateString = moment(beginningDate).format('YYYY-MM-DD');
    const endDateString = moment(endDate).format('YYYY-MM-DD');
    const response = await request({
      url: `/csd_validation`,
      method: 'POST',
      data: {
        ...formData,
        beginningDate,
        endDate,
      }
    });
    if (response.success) {
      reset();
      enqueueSnackbar("File Sent");
    } else {
      enqueueSnackbar("Error", { variant: 'error' });
    }
  }

  const renderTextField = (name, label, opts = {}) => (
    <FormControl fullWidth variant="standard">
      <TextField
        fullWidth
        label={label}
        name={name}
        inputRef={register(opts)}
        variant="standard"
      />
      { errors[name] && <FormHelperText error={ true }>{ opts.message || 'This field is required' }</FormHelperText> }
      <Box p={1} />
    </FormControl>
  );

  return (
    <Box display="flex" flexDirection="column">
      <Box
        my={5}
        ml={1}
        color="rgba(0, 0, 0, 0.5)"
        fontFamily='Roboto, "Helvetica Neue",Helvetica,Arial,sans-serif'
        fontSize="2rem"
        fontWeight={400}
      >
        CSD Data Validation Request
      </Box>
      <Box maxWidth="500px" p={2} m={0.5}>
        <form onSubmit={handleSubmit(onSubmit)}>
          { renderTextField('csdId',
                            'CSD Id',
                            { required: true}
                            ) }
          { renderTextField('dmpsId',
                            'DMPS Id',
                            { required: true}
                            ) }
          { renderTextField('locationId',
                            'Location Id',
                            { required: true}
                            ) }
          <DateSelector
            beginningDate={beginningDate}
            setBeginningDate={setBeginningDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
          <FormControl variant="standard">
            <Button color="primary" variant="contained" type="submit">Submit Request</Button>
          </FormControl>
        </form>
      </Box>
    </Box>
  );
};