import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useParams, useLocation } from 'react-router-dom';
import Link from '@mui/material/Link';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import makeStyles from '@mui/styles/makeStyles';

import Wrapper from './Wrapper';
import IntakeQScript from './IntakeQScript';
import request from '@shared/services/api/requests';


const useStyles = makeStyles(theme => ({
  ArrowBackIcon: {
    height: '15px',
    width: '15px',
    marginBottom: '-3px',
    marginRight: '3px',
  },
  link: {
    '&, &:link, &:visited, &:hover, &:active': {
      color: '#1975d2',
      textDecoration: 'none',
      fontSize: '12px',
    },
  },
}));


export default function SmartScheduler() {
  const classes = useStyles();
  const { customUrlName, iframe } = useParams();
  const [locations, setLocations] = React.useState([]);
  const [activeLocation, setActiveLocation] = React.useState(null);
  const { search } = useLocation();

  React.useEffect(() => {
    async function getLocations() {
      const response = await request({
        url: `/api/intake_practice_locations/${customUrlName}${search}`,
        method: 'GET',
      });
      setLocations(response);
      if (response.length === 1) {
        setActiveLocation(response[0]);
      }
    };
    getLocations();
  }, []);

  if (locations.length === 0) {
    return null;
  }

  const isMultiLocation = locations.length > 1;

  return (
    <Wrapper location={activeLocation} hide={!!iframe}>
      {
        activeLocation && isMultiLocation && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            p="15px"
            bgcolor="#eee"
          >
            <Box fontSize="20px">{activeLocation.name}</Box>
            <Link href="#" onClick={() => setActiveLocation(null)} className={classes.link} underline="hover">
              <ArrowBackIcon className={classes.ArrowBackIcon} /> Select different location
            </Link>
          </Stack>
        )
      }
      <Stack my={10}>
        {
          !activeLocation &&
            locations.map(location => (
            <Stack m="30px" key={location.name} mt={4} alignItems="center">
              <Stack width="60%">
                <Button variant="contained" size="small" onClick={() => setActiveLocation(location)}>
                  {location.name}
                </Button>
              </Stack>
            </Stack>
            ))
        }
        {
          activeLocation && <IntakeQScript intakeqId={activeLocation.intake_practice_id} />
        }
      </Stack>
    </Wrapper>
  )
}
