import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const stagingDomains = ['practiceengage.us', 'fairbill.me'];
let realEnv = process.env.NODE_ENV;
if (realEnv === 'production') {
  const splitHostname = window.location.hostname.split('.');
  if (stagingDomains.includes(splitHostname.slice(Math.max(splitHostname.length - 2, 0)).join('.'))) {
    realEnv = 'staging';
  }
}
Sentry.init({
  dsn: 'https://78425208aa5e4d899fb7610f2f06f41b@o297792.ingest.sentry.io/4504380053585920',
  integrations: [new BrowserTracing()],
  environment: realEnv,
  tracesSampleRate: (realEnv.includes(['staging'])) ? 1.0 : 0.05, // percent of errors sent to sentry
});
