import reviewSites from './reviewSiteImages';


export default (availableReviewSites, locale) => {
  availableReviewSites.map((site) => {
    if (site.site_name === 'Yelp') {
      return null;
    }
    const siteInfo = reviewSites(locale).find(obj => obj.name === site.site_name);
    if (!siteInfo) {
      return null;
    }
    const image = new Image();
    image.src = siteInfo.img;
    return image;
  });
};
