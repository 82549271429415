import React from 'react';
import request from '@shared/services/api/requests';
import { useSnackbar } from 'notistack';

export default function useRequest({ loadingDefault = false } = {}) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(loadingDefault);

  async function processData(data) {
    if (!data.disableLoading) setLoading(true);
    const response = await request(data);
    if (data.snackbar) {
      if (response.error) {
        enqueueSnackbar(data.snackbarErrorText || response.error || 'Error', { variant: 'error' });
      } else {
        enqueueSnackbar(data.snackbarSuccessText ? data.snackbarSuccessText : 'Success', { variant: 'success' });
      }
    }
    if (!data.disableLoading) setLoading(false);
    return response;
  }

  return {
    loading,
    request: processData,
    enqueueSnackbar,
  };
}
