import React from 'react';
import Box from '@mui/material/Box';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

export function isValidDate(d) {
  return d instanceof Date && !Number.isNaN(d) && (new Date(d)).toString() !== 'Invalid Date';
}

const classes = {
  small: {
    maxWidth: '150px',
  },
  fullWidth: {
    width: '100%',
  },
};

export default function DateTimeSelector({
  dateTime,
  setDateTime,
  minDate = new Date(),
  maxDate = null,
  minTime = null,
  maxTime = null,
  showDate = true,
  showTime = true,
  dateLabel = 'Date',
  timeLabel = 'Time',
  dateViews = ['year', 'month', 'day'],
  timeViews = ['hours', 'minutes'],
  disabled = false,
  size = 'normal',
  flexWrap = 'wrap',
  error = () => {},
  containerSx = { mt: 1 },
  useDateTimePicker = false,
}) {
  const [date, setDate] = React.useState(dateTime);
  const [time, setTime] = React.useState(dateTime);

  const handleDateChange = (value) => {
    setDate(value);
    if (isValidDate(value)) {
      setTime(value);
      setDateTime(value);
      error(false);
    } else {
      error(true);
    }
  };

  const handleTimeChange = (value) => {
    setTime(value);
    if (isValidDate(value)) {
      setDate(value);
      setDateTime(value);
      error(false);
    } else {
      error(true);
    }
  };

  return (
    <Box display="flex" alignItems="flex-end" sx={{ ...containerSx }} flexWrap={flexWrap}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        { showDate && showTime && useDateTimePicker ? (
          <DateTimePicker
            label={dateLabel}
            minDate={minDate}
            maxDate={maxDate}
            value={date}
            onChange={handleDateChange}
            disabled={disabled}
            openTo={dateViews[dateViews.length - 1]}
            views={dateViews.concat(timeViews)}
            variant="standard"
            sx={classes[size] || {}}
          />
        )
          : (
            <>
              { showDate && (
              <DatePicker
                label={dateLabel}
                minDate={minDate}
                maxDate={maxDate}
                value={date}
                onChange={handleDateChange}
                views={dateViews}
                disabled={disabled}
                openTo={dateViews[dateViews.length - 1]}
                variant="standard"
                sx={classes[size] || {}}
              />
              )}
              { showDate && showTime && (
              <Box sx={{ m: 1 }} />
              )}
              { showTime && (
              <TimePicker
                disableIgnoringDatePartForTimeValidation
                minTime={minTime}
                maxTime={maxTime}
                label={timeLabel}
                value={time}
                onChange={handleTimeChange}
                views={timeViews}
                sx={classes[size] || {}}
              />
              )}

            </>
          )}
      </LocalizationProvider>
    </Box>
  );
}
