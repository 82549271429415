import moment from 'moment';
import 'moment-timezone';

// Appt INPUT
// {
//      id: 'ID of appt',
//      time: 'datetime',
//      status: 'scheduled | confirmed | completed',
//      appointment_confirmation_request: {
//        confirmed_at: 'datetime / nil',
//      }
//      patient: {
//        id: 'id of Patient',
//        first_name: 'first name',
//        last_name: 'last name'
//      }
//   consent: True/False  // do they give consent
//   sent_at: 'datetime / nil'  // Sent Email At
//   cell_phone: '555-555-4444',
//   home_phone: '555-555-3333',
//   email: 'test5@email.com',
// }

export const isConfirmedByDoctible = (appt) => !!(
  appt.appointment_confirmation_request
  && appt.appointment_confirmation_request.confirmed_at
);
export const isConfirmedByPractice = (appt) => !!(appt.status === 'confirmed' || appt.status === 'completed');
export const isConfirmed = (appt) => (isConfirmedByDoctible(appt) || isConfirmedByPractice(appt));

const appointmentStatusDefault = {
  optOut: false,
  isConfirmed: false,
  statusText: '',
  statusLogo: false,
  statusMessage: '',
  overviewMessage: '',
};

export function getApptStatus(appt, timeZone) {
  const sentAt = (appt.sent_at) ? moment.parseZone(appt.sent_at).tz(timeZone).fromNow() : '';
  const fullName = appt.patient && [appt.patient.first_name, appt.patient.last_name].join(' ');
  const fullNamePossessive = fullName && (fullName[fullName.length - 1] === 's') ? `${fullName}'` : `${fullName}'s`;
  let appointmentStatus = {
    ...appointmentStatusDefault,
    fullName,
    fullNamePossessive,
  };
  if (appt.consent === null) {
    appt.consent = true;
  }
  if (!appt.consent) {
    appointmentStatus = {
      ...appointmentStatus,
      optOut: true,
      statusText: 'Opted Out',
    };
  }

  if (!appt.consent && isConfirmedByDoctible(appt) && isConfirmedByPractice(appt)) {
    return {
      ...appointmentStatus,
      isConfirmed: true,
      statusText: 'Confirmed',
      statusLogo: 'blue',
      statusMessage: 'Confirmed',
    };
  }
  if (!appt.consent && isConfirmedByPractice(appt)) {
    return {
      ...appointmentStatus,
      isConfirmed: true,
      statusText: 'Confirmed',
      statusMessage: 'Confirmed by You',
    };
  }
  if (!appt.consent && isConfirmedByDoctible(appt)) {
    return {
      ...appointmentStatus,
      isConfirmed: true,
      statusText: 'Confirmed',
      statusLogo: 'grey',
      statusMessage: 'Please confirm in your management system',
    };
  }
  if (!appt.consent) {
    return {
      ...appointmentStatus,
      isConfirmed: false,
      statusMessage: 'Patient opted out of automated messaging. Please contact the patient to confirm',
      overviewMessage: `${fullName} opted out of automated messaging`,
    };
  }
  if (appt.sent_at && !isConfirmedByDoctible(appt) && !isConfirmedByPractice(appt)) {
    return {
      ...appointmentStatus,
      isConfirmed: false,
      statusText: 'Unconfirmed',
      statusMessage: 'Patient has yet to confirm appointment',
      overviewMessage: `${fullName} has received a confirmation request ${sentAt}`,
    };
  }
  if (appt.sent_at && isConfirmedByDoctible(appt) && !isConfirmedByPractice(appt)) {
    return {
      ...appointmentStatus,
      isConfirmed: true,
      statusText: 'Confirmed',
      statusLogo: 'grey',
      statusMessage: 'Please confirm in your management system',
    };
  }
  if (!isConfirmedByDoctible(appt) && isConfirmedByPractice(appt)) {
    return {
      ...appointmentStatus,
      isConfirmed: true,
      statusText: 'Confirmed',
      statusMessage: 'Confirmed by You',
    };
  }
  if (appt.sent_at && isConfirmedByDoctible(appt) && isConfirmedByPractice(appt)) {
    return {
      ...appointmentStatus,
      isConfirmed: true,
      statusText: 'Confirmed',
      statusLogo: 'blue',
      statusMessage: 'Confirmed',
    };
  }
  if (!appt.cell_phone && !appt.home_phone && !appt.email) {
    return {
      ...appointmentStatus,
      isConfirmed: false,
      statusText: 'Need Contact Info',
      statusMessage: 'Please update patients contact info in your management system',
      overviewMessage: `${fullNamePossessive} contact info is missing in your system`,
    };
  }
  if (!appt.sent_at && !isConfirmedByDoctible(appt) && !isConfirmedByPractice(appt)) {
    return {
      ...appointmentStatus,
      isConfirmed: false,
      statusText: 'Unconfirmed',
      statusMessage: 'Patient has not confirmed appointment',
      overviewMessage: `${fullName} has not confirmed appointment`,
    };
  }
  return appointmentStatus;
}
