import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import StarIcon from '@mui/icons-material/Star';

import ImageSizedByWrapper from '../../../components/ImageSizedByWrapper';
import Localize from '../context/Localize';

const styles = (theme) => ({
  wrapper: {
    margin: '0 20px 20px 20px',
    fontSize: '14px',
    color: '#444444',
  },
  logoWrapper: {
    display: 'inline-block',
  },
  logoWrapperNoFilter: {
    display: 'block',
    textAlign: 'center',
  },
  ratingSummary: {
    float: 'right',
    margin: '1px 0 2px 0',
    [theme.breakpoints.up('md')]: {
      margin: '10px 0 2px 0',
    },
  },
  starsAverage: {
    display: 'inline-block',
    fontSize: '16px',
    fontWeight: '500',
    [theme.breakpoints.up('md')]: {
      fontSize: '20px',
    },
  },
  star: {
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: '-5px 0 0 5px',
    width: '22px',
    height: '22px',
    color: '#F6C84C',
  },
  totalReviews: {
    display: 'block',
    marginTop: '5px',
    fontSize: '12px',
    verticalAlign: 'text-top',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
    },
  },
  imageSizeWrapper: {
    width: '100%',
    height: '90px',
  },
});

function LocationRatingWithImage(props) {
  return (
    <Localize.Consumer>
      {(t = {}) => (
        <div className={props.classes.wrapper} data-testid="review-request-location-logo">
          <div
            className={props.filter ? props.classes.logoWrapper : props.classes.logoWrapperNoFilter}
          >
            <ImageSizedByWrapper
              url={props.url}
              alt={props.alt}
              wrapperClass={props.classes.imageSizeWrapper}
            />
          </div>
          {props.filter && props.reviewsCount >= 20 && (
          <div className={props.classes.ratingSummary}>
            <div className={props.classes.starsAverage}>
              {parseFloat(props.averageRating).toFixed(1)}
            </div>
            <StarIcon className={props.classes.star} />
            <div className={props.classes.totalReviews}>{`${props.reviewsCount} ${t.reviews}`}</div>
          </div>
          )}
        </div>
      )}
    </Localize.Consumer>
  );
}

LocationRatingWithImage.propTypes = {
  url: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  averageRating: PropTypes.number.isRequired,
  reviewsCount: PropTypes.number.isRequired,
  filter: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(LocationRatingWithImage);
