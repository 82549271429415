/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import useRequest from '@shared/services/hooks/useRequest';
import { currencyFormat } from '@shared/services/numberHelpers';
import { PaymentContext } from '../../../context/PaymentContext';
import PaymentMethod from '../PaymentMethod';
import PaymentResponse from '../../PaymentResponse';

export default function SinglePayConfirmation() {
  const {
    token, invoice, paymentMethodToken, setLoading, setStep, setInvoice,
  } = useContext(PaymentContext);
  const { request } = useRequest();
  const [paymentAuthResponse, setPaymentAuthResponse] = useState(null);

  const submitPayment = async () => {
    setLoading(true);
    const response = await request({
      url: `/payments/${token}/payments.json`,
      method: 'POST',
      data: {
        payment: {
          token: paymentMethodToken,
          amount: invoice.amountDue,
        },
      },
    });

    setLoading(false);
    if (response.error) {
      setPaymentAuthResponse(response.paymentServiceRequest);
      return;
    }

    setInvoice(response.invoice);
    setStep(2);
  };

  return (
    <>
      <Box sx={{
        display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%',
      }}
      >
        <Typography variant="h6" sx={{ my: 4 }}>
          Make a Payment
        </Typography>
        <Button color="primary" onClick={() => setStep(3)}>
          View Invoice
        </Button>
      </Box>
      <Box sx={{ mb: 10 }}>
        { paymentAuthResponse && (
        <PaymentResponse
          type={paymentAuthResponse.error ? 'error' : 'success'}
          message={paymentAuthResponse.error || 'Payment authorized!'}
        />
        )}

        <Typography sx={{ textAlign: 'center' }}>
          Amount Due
        </Typography>
        <Typography variant="h2" sx={{ textAlign: 'center' }}>
          {currencyFormat(invoice.amountDue, { maximumFractionDigits: 2 })}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Box>
          <Typography>
            Patient
          </Typography>
          <Typography variant="h6">
            {invoice.patientName}
          </Typography>
        </Box>
        <PaymentMethod />
        <Button color="primary" variant="contained" size="large" disabled={!paymentMethodToken} onClick={submitPayment}>
          Pay
        </Button>
      </Box>
    </>
  );
}
