import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import './ConfirmAppts.css';
import imgRefresh from '../images/refresh.png';
import AutoHideMessage from '../../../components/AutoHideMessage';
import PhoneLink from '../../../components/PhoneLink';

const styles = theme => ({
  allConfirmedMessage: {
    width: '310px',
    fontWeight: 'bold',
    fontSize: '18px',
    textAlign: 'center',
    marginBottom: '35px',
    color: '#96C84C',
  },
  noApptsText: {
    fontWeight: 'bold',
    fontSize: '22px',
    textAlign: 'center',
    padding: '40px 0 20px 0',
  },
  noApptsPhone: {
    fontWeight: 'bold',
    fontSize: '18px',
    textAlign: 'center',
    margin: '40px 0',
  },
  upcomingText: {
    fontWeight: 'bold',
    paddingBottom: '25px',
    fontSize: '14px',
    textAlign: 'center',
    color: '#999999',
    textTransform: 'uppercase',
    [theme.breakpoints.up('md')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    },
  },
  listItem: {
    paddingLeft: '0',
    paddingRight: '0',
  },
  checkboxDefault: {
    width: 'auto',
    height: 'auto',
  },
  checkboxChecked: {
    color: '#96C84C !important',
  },
  paper: {
    padding: '30px 20px',
    width: '310px',
  },
  patientName: {
    fontWeight: 'bold',
    fontSize: '18px',
  },
  listItemText: {
    fontSize: '15px',
    padding: '0 0 0 16px',
  },
  button: {
    textTransform: 'none',
    backgroundColor: '#96C84C',
    color: '#ffffff',
    height: '55px',
    fontSize: '18px',
    marginTop: '10px',
    '&:hover': {
      backgroundColor: '#88B645',
    },
  },
});

export class ConfirmAppts extends React.Component {
  static propTypes = {
    max_days_ahead: PropTypes.number.isRequired,
    data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    appts: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    updateCheckboxState: PropTypes.func.isRequired, // eslint-disable-line react/forbid-prop-types
    hasConfirmed: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    hasErrors: PropTypes.bool.isRequired,
    updateParentState: PropTypes.func.isRequired, // eslint-disable-line react/forbid-prop-types
    checkedAppts: PropTypes.func.isRequired, // eslint-disable-line react/forbid-prop-types
    submit: PropTypes.func.isRequired, // eslint-disable-line react/forbid-prop-types
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    checkboxState: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  }

  apptState = (appt) => {
    if (appt.isConfirmed) {
      return <FontAwesomeIcon style={{ color: '#96C84C', width: '24px' }} icon={faCheck} />;
    }
    if (this.props.isSubmitting && this.props.checkboxState[appt.id]) {
      return (
        <div style={{ width: '24px' }}>
          <img id="refresh-loader" src={imgRefresh} alt="" style={{ textAlign: 'center' }} />
        </div>
      );
    }
    return (
      <Checkbox
        classes={{
          root: this.props.classes.checkboxDefault,
          checked: this.props.classes.checkboxChecked,
        }}
        checked={this.props.checkboxState[appt.id]}
        disableRipple
      />
    );
  };

  render() {
    const { classes, appts } = this.props;
    return Object.keys(appts).length === 0 ?
           (
             <React.Fragment>
               <div className={classes.noApptsText}>
                 {`You don't have any upcoming appointments within ${this.props.max_days_ahead} days`}
               </div>
               <div className={classes.noApptsPhone}>
                 If you need to reach us call<br />
                 <PhoneLink number={this.props.data.location.primary_phone} />
               </div>
             </React.Fragment>
           )
   :
           (
             <div>
               { this.props.hasConfirmed && (
                   <AutoHideMessage
                     message="Appointment Confirmed"
                     type="success"
                     stateReset={{ hasConfirmed: false }}
                     hideMessage={this.props.updateParentState}
                   />
               )}
               { this.props.hasErrors && (
                   <AutoHideMessage
                     message="Error Submitting Form"
                     type="error"
                     stateReset={{ hasErrors: false }}
                     hideMessage={this.props.updateParentState}
                   />
               )}
               { Object.keys(this.props.checkboxState).length === 0 && (
                   <div className={classes.allConfirmedMessage}>
                     {"You've confirmed all of your upcoming appointments"}
                   </div>
               )}
               <Paper
                 classes={{ root: classes.paper }}
                 elevation={4}
               >
                 <div className={classes.upcomingText}>
                   {`Upcoming appts within ${this.props.max_days_ahead} days`}
                 </div>
                 {
                   Object.keys(appts).map(patientId => (
                     <div key={patientId}>
                       <div className={classes.patientName}>
                         {appts[patientId][0].fullName}
                       </div>
                       <List>
                         {
                           appts[patientId].map(appt => (
                             <ListItem
                               key={appt.id}
                               classes={{ root: classes.listItem }}
                               dense
                               button
                               disableRipple={appt.isConfirmed}
                               onClick={this.props.updateCheckboxState(appt.id)}
                               >
                               { this.apptState(appt) }
                               <ListItemText
                                 classes={{ root: classes.listItemText }}
                                 primary={appt.momentTime.format('ddd - MMM D, YYYY [at] h:mm A')}
                               />
                             </ListItem>
                           ))
                         }
                       </List>
                     </div>
                   ))
                 }
                 { Object.keys(this.props.checkboxState).length !== 0 && (
                     <Button
                       variant="contained"
                       fullWidth
                       classes={{
                         root: classes.button,
                       }}
                       onClick={this.props.submit}
                       disabled={this.props.checkedAppts().length === 0 || this.props.isSubmitting}
                       >
                       Confirm
                     </Button>
                 )}
               </Paper>
             </div>
           );
  }
}

export default withStyles(styles)(ConfirmAppts);
