import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';

const styles = {
  wrapper: {
    display: 'inline-block',
  },
  img: {
    maxHeight: '100%',
    maxWidth: '100%',
  },
};

function ImageSizedByWrapper(props) {
  return (
    <div
      className={
      classNames({
        [props.classes.wrapper]: true,
        [props.wrapperClass]: !!props.wrapperClass,
      })
      }
    >
      <img
        className={props.classes.img}
        src={props.url}
        alt={props.alt}
      />
    </div>
  );
}

ImageSizedByWrapper.defaultProps = {
  wrapperClass: null,
};

ImageSizedByWrapper.propTypes = {
  url: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  wrapperClass: PropTypes.string,
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(ImageSizedByWrapper);
