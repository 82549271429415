import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import clickableWithData from '@shared/components/clickableWithData';
import reviewImagesPreLoad from './reviewImagesPreLoad';
import Localize from '../context/Localize';

const ButtonWithData = clickableWithData(Button);

const styles = theme => ({
  wrapper: {
    textAlign: 'center',
  },
  headlineText: {
    fontSize: '26px',
    fontWeight: '500',
    padding: '25px 0 0 0',
    [theme.breakpoints.up('md')]: {
      fontSize: '40px',
    },
  },
  buttonYes: {
    textTransform: 'none',
    backgroundColor: '#96C84C',
    color: '#ffffff',
    height: '40px',
    fontSize: '14px',
    marginTop: '40px',
    '&:hover': {
      backgroundColor: '#88B645',
    },
    '&:focused': {
      backgroundColor: '#88B645',
    },
    '&:active': {
      backgroundColor: '#88B645',
    },
    '&:visited': {
      backgroundColor: '#88B645',
    },
    '&:disabled': {
      backgroundColor: '#BCDA90',
    },
    [theme.breakpoints.up('md')]: {
      height: '60px',
      fontSize: '21px',
      marginTop: '60px',
    },
  },
  loadingYes: {
    color: '#ffffff',
  },
  buttonNo: {
    textTransform: 'none',
    color: '#444444',
    height: '40px',
    fontSize: '14px',
    marginTop: '30px',
    [theme.breakpoints.up('md')]: {
      height: '60px',
      fontSize: '21px',
      marginTop: '40px',
    },
  },
});

export class YesNo extends React.Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    universal: PropTypes.bool,
    updateStarState: PropTypes.func.isRequired, // eslint-disable-line react/forbid-prop-types
    isSubmitting: PropTypes.bool.isRequired,
    starState: PropTypes.number.isRequired,
    submit: PropTypes.func.isRequired, // eslint-disable-line react/forbid-prop-types
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    availableReviewSites: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  componentDidMount() {
    reviewImagesPreLoad(this.props.availableReviewSites, this.props.locale);
  }

  submit = (data) => {
    if (this.props.universal) {
      this.props.updateStarState(data.stars);
    } else {
      this.props.submit(data.stars);
    }
  };

  render() {
    const { starState, classes } = this.props;
    return (
      <Localize.Consumer>
        {(t = {}) => (
          <div className={classes.wrapper}>
            <div className={classes.headlineText}>{t.would_you_recommend_your_experience}</div>
            <ButtonWithData
              data={{ stars: 5 }}
              handleClick={this.submit}
              classes={{
                root: classes.buttonYes,
              }}
              fullWidth
              variant="contained"
              disabled={this.props.isSubmitting}
            >
              {this.props.starState === 5 && this.props.isSubmitting ? (
                <CircularProgress className={classes.loadingYes} size={24} />
              ) : (
                t.text_yes
              )}
            </ButtonWithData>
            <ButtonWithData
              data={{ stars: 3 }}
              handleClick={this.submit}
              classes={{
                root: classes.buttonNo,
              }}
              disabled={this.props.isSubmitting}
            >
              {this.props.starState === 3 && this.props.isSubmitting ? (
                <CircularProgress className={classes.loading} size={24} />
              ) : (
                t.not_really
              )}
            </ButtonWithData>
          </div>
        )}
      </Localize.Consumer>
    );
  }
}

export default withStyles(styles)(YesNo);
