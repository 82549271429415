import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Paper from '@mui/material/Paper';

import LocationRatingImage from './LocationRatingImage';
import LocationRatingNoImage from './LocationRatingNoImage';

const styles = theme => ({
  wrapper: {
    display: 'table-cell',
    width: '600px',
  },
  paper: {
    margin: '0 10px',
    minWidth: '300px',
    maxWidth: '600px',
    [theme.breakpoints.up('md')]: {
      margin: '0 20px',
    },
  },
  content: {
    padding: '15px 20px 45px 20px',
    [theme.breakpoints.up('md')]: {
      padding: '30px 40px 50px 40px',
    },
  },
});

const ContentWrapper = props => (
  <div className={props.classes.wrapper}>
    {props.showLogo && (
      <LocationRatingImage
        url={props.location.logo_url}
        alt={props.location.name}
        averageRating={props.location.average_rating || 0}
        reviewsCount={props.location.reviews_count}
        filter={props.location.review_request_filter}
      />
    )}
    <Paper classes={{ root: props.classes.paper }} elevation={4}>
      {(!props.location.review_request_filter || !props.showLogo) && props.showLocationName && props.location && (
        <LocationRatingNoImage
          name={props.location.name}
          averageRating={props.location.average_rating || 0}
          reviewsCount={props.location.reviews_count}
          filter={props.location.review_request_filter}
        />
      )}
      <div className={props.classes.content}>{props.children}</div>
    </Paper>
  </div>
);

ContentWrapper.propTypes = {
  showLogo: PropTypes.bool.isRequired,
  showLocationName: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(ContentWrapper);
