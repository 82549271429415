import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import Rating from 'react-rating';
import StarIcon from '@mui/icons-material/Star';

import FormButton from './FormButton';
import reviewImagesPreLoad from './reviewImagesPreLoad';
import Localize from '../context/Localize';

const styles = theme => ({
  wrapper: {
    textAlign: 'center',
  },
  headlineText: {
    fontSize: '21px',
    fontWeight: '500',
    padding: '25px 0 0 0',
    [theme.breakpoints.up('md')]: {
      fontSize: '40px',
    },
  },
  star: {
    margin: '35px 0 0 0',
    width: '50px',
    height: '50px',
    [theme.breakpoints.up('md')]: {
      margin: '35px 0 0 0',
      width: '80px',
      height: '80px',
    },
  },
  starEmpty: {
    color: '#666666',
  },
  starFull: {
    color: '#F6C84C',
  },
  chip: {
    margin: '10px 0 0 0',
    height: '35px',
    lineHeight: '35px',
    fontSize: '16px',
    verticalAlign: 'middle',
    color: '#444444',
    backgroundColor: '#ffffff',
    [theme.breakpoints.up('md')]: {
      fontSize: '22px',
      height: '50px',
      lineHeight: '50px',
    },
  },
});

const sanitizeRating = (rating) => {
  if (rating < 1) { return 1; }
  if (rating > 5) { return 5; }
  return rating;
};

export class StarRating extends React.Component {
  static propTypes = {
    universal: PropTypes.bool,
    locale: PropTypes.string.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    starState: PropTypes.number.isRequired,
    updateStarState: PropTypes.func.isRequired, // eslint-disable-line react/forbid-prop-types
    submit: PropTypes.func.isRequired, // eslint-disable-line react/forbid-prop-types
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    availableReviewSites: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  }
  static defaultProps = {
    universal: false,
  }

  state = {
    isHovering: false,
    starStateHover: 0,
  };

  componentDidMount() {
    reviewImagesPreLoad(this.props.availableReviewSites, this.props.locale);
  }

  starChanged = (value) => {
    this.props.updateStarState(sanitizeRating(value));
  }

  starHover = (value) => {
    if (typeof value === 'undefined') {
      this.setState({
        isHovering: false,
      });
      return;
    }
    if (sanitizeRating(value) === this.state.starStateHover && this.state.isHovering) {
      return;
    }
    this.setState({
      isHovering: true,
      starStateHover: sanitizeRating(value),
    });
  }

  submit = () => {
    this.props.submit(this.props.starState);
  }

  render() {
    const { starState, classes } = this.props;
    const { isHovering, starStateHover } = this.state;
    const selectedRating = isHovering ? starStateHover : starState;
    return (
      <Localize.Consumer>
        {(t = {}) => (
          <div className={classes.wrapper} >
            <div className={classes.headlineText}>{t.how_was_your_experience}</div>
            <Rating
              emptySymbol={
                [1, 2, 3, 4, 5].map((n, i) => (
                  (n <= ((isHovering) ? starStateHover : starState)) ?
                    <StarIcon data-testid={`star-rating-empty${i}`} className={`${classes.star} ${classes.starFull}`} />
                    :
                    <StarIcon data-testid={`star-rating-empty${i}`} className={`${classes.star} ${classes.starEmpty}`} />
                ))
              }
              fullSymbol={<StarIcon className={`${classes.star} ${classes.starFull}`} />}
              placeholderSymbol=""
              initialRating={this.props.universal ? 0 : starState}
              placeholderRating={starState}
              onChange={this.starChanged}
              onHover={this.starHover}
            />
            <div className={classes.chip}>{(selectedRating > 0) ? t[`rating_label_${selectedRating}`] : ''}</div>
            {
              !this.props.universal &&
              <FormButton
                text={t.next}
                submit={this.submit}
                disabled={!starState || this.props.isSubmitting}
                isSubmitting={this.props.isSubmitting}
              />
            }
          </div>
        )}
      </Localize.Consumer>
    );
  }
}

export default withStyles(styles)(StarRating);
