import axios from 'axios';

export default async ({
  url = '',
  method = 'POST',
  responseType = 'json',
  data,
  isFile = false,
}) => {
  const contentType = isFile ? 'multipart/form-data' : 'application/json';
  try {
    const tokenElement = document.head.querySelector('meta[name=csrf-token]');
    const token = tokenElement !== null ? tokenElement.content : null;
    const response = await axios({
      url,
      method,
      responseType,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': contentType,
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': token,
      },
      data: isFile ? data : JSON.stringify(data),
    });
    if (response.status >= 400) {
      throw new Error('Bad response from server');
    }
    return response.data;
  } catch (e) {
    if (e.response && (e.response.status === 403 || e.response.status === 401)) {
      window.location.reload(true);
    }
    console.log(e);
    return { error: e.message || 'Unknown Error', message: e, data: e.response?.data };
  }
};
