/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState, useContext, useMemo, useRef, useEffect,
} from 'react';
import moment from 'moment';
import SignatureCanvas from 'react-signature-canvas';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';

import useRequest from '@shared/services/hooks/useRequest';
import { currencyFormat } from '@shared/services/numberHelpers';
import { pluralize } from '@shared/services/wordHelpers';
import PaymentMethod from '../PaymentMethod';
import { PaymentContext } from '../../../context/PaymentContext';

export default function FixedPlanConfirmation() {
  const {
    token, invoice, setInvoice, paymentMethodToken, setStep, setLoading,
  } = useContext(PaymentContext);
  const { request, loading, enqueueSnackbar } = useRequest();

  const [acceptedTerms, setAcceptedTerms] = useState(invoice.acceptedTos || false);
  const [signer, setSigner] = useState(invoice.signer || 'patient');
  const [signature, setSignature] = useState(null);
  const sigCanvasRef = useRef(null);

  const planAuthorized = useMemo(() => (
    paymentMethodToken && acceptedTerms && signer && signature
  ), [paymentMethodToken, acceptedTerms, signer, signature]);

  const saveSignature = () => {
    setSignature(sigCanvasRef.current.getTrimmedCanvas().toDataURL());
  };

  const resetSignature = () => {
    sigCanvasRef.current.clear();
    setSignature(null);
  };

  const handlePlanApproval = async () => {
    if (!paymentMethodToken) {
      enqueueSnackbar('You must select a payment method', { variant: 'error' });
      return;
    }
    if (!acceptedTerms) {
      enqueueSnackbar('You must accept the terms and conditions', { variant: 'error' });
      return;
    }
    if (!signature) {
      enqueueSnackbar('You must sign the agreement', { variant: 'error' });
      return;
    }

    const formData = new FormData();
    formData.append('token', paymentMethodToken);
    formData.append('signature', signature);
    formData.append('invoice[accepted_tos]', acceptedTerms);
    formData.append('invoice[signer]', signer);
    const response = await request({
      url: `/payments/${token}/approve_plan.json`,
      method: 'PUT',
      isFile: true,
      data: formData,
    });

    if (response.error) {
      enqueueSnackbar('Error authorizing Payment Plan. Please try again.', { variant: 'error' });
    } else {
      enqueueSnackbar('Payment Plan authorized successfully', { variant: 'success' });
      setInvoice(response.invoice);
    }
  };

  const handlePaymentMethodUpdate = async () => {
    const response = await request({
      url: `/payments/${token}/update_payment_method.json`,
      method: 'PUT',
      data: { token: paymentMethodToken },
    });
    if (response.error) {
      enqueueSnackbar('Error updating Payment Method. Please try again.', { variant: 'error' });
    } else {
      enqueueSnackbar('Payment Method successfully updated', { variant: 'success' });
    }
  };

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  return (
    <>
      <Box sx={{
        display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%',
      }}
      >
        <Typography variant="h6" sx={{ my: 4 }}>
          Review Payment Plan
        </Typography>
        <Button color="primary" onClick={() => setStep(3)}>
          Detailed Info
        </Button>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
        <Box>
          <Typography sx={{ textAlign: 'center' }}>
            Total Amount
          </Typography>
          <Typography variant="h2" sx={{ textAlign: 'center' }}>
            {currencyFormat(invoice.amount, { maximumFractionDigits: 2 })}
          </Typography>
          <Typography sx={{ textAlign: 'center' }}>
            {currencyFormat(invoice.paymentAmount, { maximumFractionDigits: 2 })}
            {' '}
            each
            {' '}
            {invoice.timeUnit}
            {' '}
            for
            {' '}
            {invoice.timeInterval}
            {' '}
            { pluralize(invoice.timeInterval, invoice.timeUnit) }
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <Typography variant="h6">Payment Plan Schedule:</Typography>
          { Array.from({ length: invoice.timeInterval }, (_, i) => {
            const date = moment(invoice.startDate).add(i, 'months');
            const amount = i === (invoice.timeInterval - 1) ? (invoice.amount - (invoice.paymentAmount * i)) : invoice.paymentAmount;
            return (
              <Box key={i} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography>
                  {date.format('MM/DD/YY')}
                </Typography>
                <Typography>
                  {currencyFormat(amount, { maximumFractionDigits: 2 })}
                </Typography>
              </Box>
            );
          })}
        </Box>

        <PaymentMethod />

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <Box>
            <Typography textAlign="justify">
              By continuing, you agree to the
              {' '}
              <b><u>terms and conditions</u></b>
              {' '}
              and authorize payments to be made according to the payment schedule above
            </Typography>
            <FormControl>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={invoice.acceptedTos || acceptedTerms}
                    onChange={(event) => { setAcceptedTerms(event.target.checked); }}
                    color="primary"
                    disabled={invoice.acceptedTos}
                  />
                  )}
                label="I have reviewed the information above and agree to the terms and conditions"
              />
            </FormControl>
          </Box>

          { invoice.signer ? (
            <Box>
              <Typography>
                Signed by the
                {' '}
                {invoice.signer}
                {' '}
                on
                {' '}
                {invoice.signatureDate}
              </Typography>
            </Box>
          ) : (
            <>
              <Box>
                <Typography>Signing as</Typography>
                <FormControl fullWidth>
                  <RadioGroup
                    value={invoice.signer || signer}
                    sx={{
                      display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2,
                    }}
                  >
                    <FormControlLabel
                      value="patient"
                      disabled={invoice.signer}
                      control={<Radio />}
                      label="Patient"
                      onClick={() => { setSigner('patient'); }}
                    />
                    <FormControlLabel
                      value="guardian"
                      disabled={invoice.signer}
                      control={<Radio />}
                      label="Parent/Guardian"
                      onClick={() => { setSigner('guardian'); }}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box>
                <SignatureCanvas ref={sigCanvasRef} onEnd={saveSignature} canvasProps={{ style: { width: '100%', height: '160px', border: '1px solid black' } }} />
                <Button color="secondary" variant="contained" size="small" onClick={resetSignature}>Clear</Button>
              </Box>
            </>
          )}
        </Box>

        { invoice.acceptedTos ? (
          <Button color="primary" variant="contained" size="large" fullWidth disabled={!paymentMethodToken || invoice.paymentMethodToken === paymentMethodToken} onClick={handlePaymentMethodUpdate}>
            Update
          </Button>
        ) : (
          <Button color="primary" variant="contained" size="large" fullWidth disabled={!planAuthorized} onClick={handlePlanApproval}>
            Confirm
          </Button>
        )}
      </Box>
    </>
  );
}
