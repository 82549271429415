import PropTypes from 'prop-types';
import React from 'react';
import { SnackbarProvider } from 'notistack';
import withStyles from '@mui/styles/withStyles';
import { darken } from '@material-ui/core/styles';

const styles = (theme) => ({
  base: {
    fontSize: 14,
  },
  info: {
    borderLeftColor: theme.palette.primary.main,
    backgroundColor: `${darken(theme.palette.info.main, 0.1)} !important`,
  },
  success: {
    borderLeftColor: theme.palette.primary.main,
    backgroundColor: `${darken(theme.palette.success.main, 0.1)} !important`,
  },
  error: {
    borderLeftColor: theme.palette.primary.dark,
    backgroundColor: `${darken(theme.palette.error.main, 0.1)} !important`,
  },
  warning: {
    borderLeftColor: theme.palette.warning,
    backgroundColor: `${darken(theme.palette.warning.main, 0.1)} !important`,
  },
});

function SnackBar(props) {
  const { children, classes, ...rest } = props;

  return (
    <SnackbarProvider
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      classes={{
        base: classes.base,
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}
      preventDuplicate
    >
      {children}
    </SnackbarProvider>
  );
}

SnackBar.propTypes = {
  children: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(SnackBar);
