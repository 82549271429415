import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

const styles = {
  AddressDisplay: {
    textAlign: 'center',
    padding: '20px',
    fontSize: 'inherit',
  },
  link: {
    color: 'inherit',
    fontSize: 'inherit',
    display: 'inline-block',
    textDecoration: 'underline',
    '&:link': {
      color: 'inherit',
      textDecoration: 'underline',
    },
    '&:visited': {
      color: 'inherit',
      textDecoration: 'underline',
    },
    '&:hover': {
      color: 'inherit',
      textDecoration: 'underline',
    },
    '&:active': {
      color: 'inherit',
      textDecoration: 'underline',
    },
  },
};

const AddressDisplay = (props) => {
  const address = `${props.address_1} ${props.address_2 ? props.address_2 : ''}`;
  const cityStateZip = `${props.city}, ${props.state} ${props.zip}`;
  return (
    <div
      className={props.classes.AddressDisplay}
    >
      <div>{props.name}</div>
      <a
        className={props.classes.link}
        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address + cityStateZip)}`}
        target="_blank"
      >
        <div>{address}</div>
        <div>{cityStateZip}</div>
      </a>
    </div>
  );
};

AddressDisplay.propTypes = {
  name: PropTypes.string.isRequired,
  address_1: PropTypes.string.isRequired,
  address_2: PropTypes.string,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

AddressDisplay.defaultProps = {
  address_2: null,
};

export default withStyles(styles)(AddressDisplay);
