import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

const styles = {
  AlignCenterContainer: {
    float: 'right',
    position: 'relative',
    left: '-50%',
  },
  AlignCenterWrapper: {
    position: 'relative',
    left: '50%',
  },
};

const AlignCenter = props => (
  <div className={props.classes.AlignCenterContainer}>
    <div className={props.classes.AlignCenterWrapper}>
      {props.children}
    </div>
  </div>
);

AlignCenter.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(AlignCenter);
