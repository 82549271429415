import React from 'react';
import Box from '@mui/material/Box';
import moment from 'moment';
import 'moment-timezone';

import DateTimeSelector from '@shared/components/DateTimeSelector';

export default function DateSelector({
  beginningDate,
  setBeginningDate,
  endDate,
  setEndDate,
}) {
  return (
    <Box>
      <Box
        mt={3}
        color="rgba(0, 0, 0, 0.54)"
        fontFamily='Roboto, "Helvetica Neue",Helvetica,Arial,sans-serif'
        fontSize="1.1rem"
        fontWeight={400}
      >
        Choose the date range
      </Box>
      <Box display="flex">
        <Box flex={1} mr={2}>
          <DateTimeSelector
            dateLabel="From"
            dateTime={beginningDate}
            setDateTime={setBeginningDate}
            minDate={moment().subtract(6, 'day').toDate()}
            maxDate={moment().add(6, 'day').toDate()}
            showTime={false}
          />
        </Box>
        <Box flex={1} m={2} mt={0}>
          <DateTimeSelector
            dateLabel="To"
            dateTime={endDate}
            setDateTime={setEndDate}
            minDate={moment().subtract(6, 'day').toDate()}
            maxDate={moment().add(6, 'day').toDate()}
            showTime={false}
          />
        </Box>
      </Box>
    </Box>
  );
}
