import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import currentLocation from '@practice_users/services/locationHelper';

const styles = {
  wrapper: {
    textAlign: 'center',
  },
  link: {
    color: '#269CD6',
    fontSize: 'inherit',
    display: 'inline-block',
    textDecoration: 'none',
    '&:link': {
      color: '#269CD6',
      textDecoration: 'none',
    },
    '&:visited': {
      color: '#269CD6',
      textDecoration: 'none',
    },
    '&:hover': {
      color: '#269CD6',
      textDecoration: 'none',
    },
    '&:active': {
      color: '#269CD6',
      textDecoration: 'none',
    },
  },
};

function PoweredByDoctible(props) {
  return (
    <div className={props.classes.wrapper}>
      {`${props.preText} `}
      <a
        className={props.classes.link}
        href={currentLocation.brand.poweredByUrl}
        target="_blank"
        rel="noreferrer"
      >
        {currentLocation.brand.name}
      </a>
    </div>
  );
}

PoweredByDoctible.defaultProps = {
  preText: 'Powered by',
};

PoweredByDoctible.propTypes = {
  preText: PropTypes.string,
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(PoweredByDoctible);
