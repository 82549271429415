import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import reviewSites from './reviewSiteImages';

const styles = theme => ({
  link: {
    marginTop: '15px',
  },
  img: {
    marginTop: '15px',
    width: '94%',
    [theme.breakpoints.up('md')]: {
      width: '90%',
    },
  },
});

const positionSort = (a, b) => a.position - b.position;

const ReviewLinks = ({
  classes,
  locale,
  starState,
  availableReviewSites,
  recordReviewSiteClick,
}) => {
  const orderedAvailableReviewSites = availableReviewSites.sort(positionSort);
  const recordClick = (locationReviewSiteId, siteName) => {
    recordReviewSiteClick(locationReviewSiteId, siteName);
  };
  return (
    <React.Fragment>
      {orderedAvailableReviewSites.map((site) => {
        const siteInfo = reviewSites(locale).find(obj => obj.name === site.site_name);
        if (!siteInfo) return null;
        if (site.site_name === 'Yelp') return null;
        if (starState <= 3 && site.site_name !== 'Google') return null;
        return (
          <a
            key={site.id}
            className={classes.link}
            href={site.url}
            target="_blank" // eslint-disable-line react/jsx-no-target-blank
            onClick={() => recordClick(site.id, site.site_name)}
          >
            <img className={classes.img} src={siteInfo.img} alt="" />
          </a>
        );
      })}
    </React.Fragment>
  );
};

ReviewLinks.propTypes = {
  locale: PropTypes.string.isRequired,
  recordReviewSiteClick: PropTypes.func, // eslint-disable-line react/forbid-prop-types
  availableReviewSites: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  starState: PropTypes.number,
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

ReviewLinks.defaultProps = {
  recordReviewSiteClick: () => {},
  starState: 5,
};
export default withStyles(styles)(ReviewLinks);
