import React, { useContext, useEffect } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { currencyFormat } from '@shared/services/numberHelpers';
import PaymentsList from '@shared/components/PaymentsList';
import { PaymentContext } from '../../context/PaymentContext';
import PaymentResponse from '../PaymentResponse';

export default function PaymentAuthorized() {
  const { invoice, token, setStep } = useContext(PaymentContext);

  useEffect(() => {
    if (Number.parseInt(invoice.amountDue, 10) !== 0) {
      setStep(1);
    }
  }, [invoice.status]);

  return (
    <Box sx={{ width: '100%', height: '100%', mb: 4 }}>
      <Box sx={{
        display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%',
      }}
      >
        <Typography variant="h6" sx={{ my: 4 }}>
          Make a Payment
        </Typography>
        <Button color="primary" onClick={() => setStep(3)}>
          View Invoice
        </Button>
      </Box>
      <Box sx={{ mb: 10 }}>
        <PaymentResponse
          type="success"
          message={'You\'re all set!'}
        />

        <Typography sx={{ textAlign: 'center' }}>
          Total Amount Paid
        </Typography>
        <Typography variant="h2" sx={{ textAlign: 'center' }}>
          {currencyFormat(invoice.amountPaid, { maximumFractionDigits: 2 })}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Box>
          <Typography>
            Patient
          </Typography>
          <Typography variant="h6">
            {invoice.patientName}
          </Typography>
        </Box>
      </Box>

      { invoice.payments.length > 0 && (
        <Box sx={{ mt: 6 }}>
          <PaymentsList
            invoice={invoice}
            baseUrl={`/payments/${token}/payments/`}
          />
        </Box>
      ) }
    </Box>
  );
}
