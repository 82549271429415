import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const styles = theme => ({
  button: {
    textTransform: 'none',
    backgroundColor: '#96C84C',
    color: '#ffffff',
    height: '40px',
    fontSize: '14px',
    marginTop: '20px',
    '&:hover': {
      backgroundColor: '#88B645',
    },
    '&:focused': {
      backgroundColor: '#88B645',
    },
    '&:active': {
      backgroundColor: '#88B645',
    },
    '&:visited': {
      backgroundColor: '#88B645',
    },
    '&:disabled': {
      backgroundColor: '#BCDA90',
    },
    [theme.breakpoints.up('md')]: {
      height: '60px',
      fontSize: '21px',
      marginTop: '16px',
    },
  },
  loading: {
    color: '#ffffff',
  },
});

const FormButton = ({
  classes, text, submit, disabled, isSubmitting,
}) => (
  <Button
    variant="contained"
    fullWidth
    classes={{
      root: classes.button,
    }}
    onClick={submit}
    disabled={disabled}
    data-testid="feedback-submit-button"
  >
    {isSubmitting ? <CircularProgress className={classes.loading} size={24} /> : text}
  </Button>
);

FormButton.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  text: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired, // eslint-disable-line react/forbid-prop-types
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

FormButton.defaultProps = {
  disabled: false,
};

export default withStyles(styles)(FormButton);
