import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Rating from 'react-rating';
import StarIcon from '@mui/icons-material/Star';

import { roundToDenominator } from '@shared/services/mathHelpers';
import Localize from '../context/Localize';

const styles = theme => ({
  wrapper: {
    padding: '18px 18px 14px',
    fontSize: '14px',
    backgroundColor: '#269CD6',
    color: '#ffffff',
    [theme.breakpoints.up('md')]: {
      fontSize: '20px',
    },
  },
  ratingSummary: {
    margin: '8px 0 0 0',
  },
  starsAverage: {
    display: 'inline-block',
    fontSize: '16px',
    fontWeight: '500',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
    },
  },
  stars: {
    display: 'inline-block',
    margin: '1px 10px 0 5px',
    verticalAlign: 'middle',
  },
  totalReviews: {
    display: 'inline-block',
    fontSize: '12px',
    verticalAlign: 'text-top',
    [theme.breakpoints.up('md')]: {
      marginTop: '4px',
      fontSize: '14px',
    },
  },
  star: {
    width: '16px',
    height: '16px',
    [theme.breakpoints.up('md')]: {
      width: '20px',
      height: '20px',
    },
  },
  starEmpty: {
    color: '#269CD6',
  },
  starFull: {
    color: '#ffffff',
  },
});

const LocationRatingNoImage = props => (
  <Localize.Consumer>
    {(t = {}) => (
      <div className={props.classes.wrapper}>
        <div>{props.name}</div>
        {props.filter && props.reviewsCount >= 20 && (
          <div className={props.classes.ratingSummary}>
            <div className={props.classes.starsAverage}>
              {parseFloat(props.averageRating).toFixed(1)}
            </div>
            <div className={props.classes.stars}>
              <Rating
                emptySymbol={
                  <StarIcon className={`${props.classes.star} ${props.classes.starEmpty}`} />
                }
                fullSymbol={
                  <StarIcon className={`${props.classes.star} ${props.classes.starFull}`} />
                }
                initialRating={roundToDenominator(props.averageRating, 2)}
                readonly
              />
            </div>
            <div className={props.classes.totalReviews}>{`${props.reviewsCount} ${t.reviews}`}</div>
          </div>
        )}
      </div>
    )}
  </Localize.Consumer>
);

LocationRatingNoImage.propTypes = {
  name: PropTypes.string.isRequired,
  averageRating: PropTypes.number.isRequired,
  reviewsCount: PropTypes.number.isRequired,
  filter: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(LocationRatingNoImage);
