/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';

import Box from '@mui/material/Box';
import { PaymentContext } from '../../context/PaymentContext';
import FixedPlanConfirmation from './FixedPlanConfirmation';
import SinglePayConfirmation from './SinglePayConfirmation';

export default function PaymentConfirmation() {
  const { invoice } = useContext(PaymentContext);

  return (
    <Box sx={{ width: '100%', height: '100%', mb: 4 }}>
      { invoice.invoiceType === 'single_pay' && (
        <SinglePayConfirmation />
      )}
      { invoice.invoiceType === 'fixed_plan' && (
        <FixedPlanConfirmation />
      )}
    </Box>
  );
}
