import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import ImageSizedByWrapper from '../../../components/ImageSizedByWrapper';

const styles = {
  headerContainer: {
    textAlign: 'center',
    margin: '20px 0',
  },
  locationName: {
    width: '310px',
  },
  imageSizeWrapper: {
    width: '200px',
    height: '60px',
  },
};

const Header = ({ data, classes }) => (
  <div className={classes.headerContainer}>
    {data.location.logo_url ? (
      <ImageSizedByWrapper
        url={data.location.logo_url}
        alt={data.location.name}
        wrapperClass={classes.imageSizeWrapper}
      />
    ) : (
      <h2 className={classes.locationName}>{data.location.name}</h2>
    )}
  </div>
);

Header.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(Header);
