import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import request from '@shared/services/api/requests';
import NoFilterReview from './NoFilterReview';
import NoFilterReviewCustomText from './NoFilterReviewCustomText';
import FeedbackForm from './FeedbackForm';
import ThankYou from './ThankYou';

class NoFilterExperience extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  constructor(props) {
    super(props);
    const data = JSON.parse(document.getElementById('reactData').innerHTML);

    this.state = {
      locale: data.locale ? data.locale : 'en',
      location: data.location,
      universal: data.universal,
      isSubmitting: false,
      feedbackSubmitted: !!data.review_request.message,
      availableReviewSites: data.available_review_sites,
      token: data.universal ? null : props.match.params.token,
      contentTitle: data.content_title,
      contentBody: data.content_body,
    };
    this.universalLink = `/review_requests/universal/${this.state.location.custom_url_name}`;
    this.tokenLink = `/review_requests/${this.state.token}`;
  }

  backButton = () => {
    this.props.history.push(this.state.universal ? this.universalLink : this.tokenLink);
  };

  submitFeedback = async (values) => {
    this.setState({
      isSubmitting: true,
    });
    let data = {
      token: this.state.token,
      rating: 3,
      message: values.message,
    };
    if (this.state.universal) {
      data = {
        ...data,
        name: values.name,
        email: values.email,
      };
    }
    const response = await request({
      url: this.props.match.url,
      method: 'PUT',
      data,
    });
    if (response.error) {
      this.setState({
        isSubmitting: false,
        feedbackSubmitted: false,
      });
      return false;
    }
    this.setState({
      token: response && response.token ? response.token : this.state.token,
      isSubmitting: false,
      feedbackSubmitted: true,
    });
    this.backButton();
    return true;
  };

  recordReviewSiteClick = async (locationReviewSiteId, siteName) => {
    const response = await request({
      url: '/api/review_requests/review_site_clicked',
      method: 'POST',
      data: {
        token: this.state.token,
        location_review_site_id: locationReviewSiteId,
        site_name: siteName,
      },
    });
    if (response && response.token) {
      this.setState({
        token: response.token,
      });
    }
    return true;
  };

  render() {
    const onFeedbackForm = this.props.location.pathname.includes('feedback');
    const token = this.props.match.params.token;
    return (
      <React.Fragment>
        {!this.state.feedbackSubmitted && !onFeedbackForm && this.state.contentBody && (
          <NoFilterReviewCustomText
            locale={this.state.locale}
            availableReviewSites={this.state.availableReviewSites}
            recordReviewSiteClick={this.recordReviewSiteClick}
            universal={this.state.universal}
            contentBody={this.state.contentBody}
            contentTitle={this.state.contentTitle}
          />
        )}
        {!this.state.feedbackSubmitted && !onFeedbackForm && !this.state.contentBody && (
          <NoFilterReview
            locale={this.state.locale}
            availableReviewSites={this.state.availableReviewSites}
            recordReviewSiteClick={this.recordReviewSiteClick}
            universal={this.state.universal}
            contentBody={this.state.contentBody}
            contentTitle={this.state.contentTitle}
          />
        )}
        {onFeedbackForm && (
          <FeedbackForm
            universal={this.state.universal}
            backButton={this.backButton}
            starState={3}
            isSubmitting={this.state.isSubmitting}
            submit={this.submitFeedback}
            isFilterExperience={false}
            token={token}
          />
        )}
        {this.state.feedbackSubmitted && <ThankYou starState={this.state.starState} />}
      </React.Fragment>
    );
  }
}

export default withRouter(NoFilterExperience);
