export const pluralize = (count, noun, suffix = 's') => `${noun}${count !== 1 ? suffix : ''}`;

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const snakeToLowerCamelCase = (string) => string.split('_').map((word, ind) => (ind === 0 ? word : capitalizeFirstLetter(word))).join('');

export const lowerCamelToSnakeCase = (string) => string.replace(/([A-Z])/g, '_$1').toLowerCase();

export const truncateWithWholeWords = (string, maxLength) => {
  const trimmedString = string.substring(0, maxLength);
  const lastWordLimit = Math.min(trimmedString.length, trimmedString.lastIndexOf(' '));

  return [string.substring(0, lastWordLimit), string.substring(lastWordLimit)];
};
