import React from 'react';

export default function IntakeQScript({ intakeqId }) {
  return (
    <React.Fragment>
      {
        (
          function (c) {
            window.intakeq= intakeqId;
            window.intakeqHost="intake.fairbill.me";
            var i = c.createElement("script");
            i.type = "text/javascript";
            i.async = true;
            i.src = "https://intake.fairbill.me/js/widget.min.js?1";
            document.head.appendChild(i);
          }
        )(document)
      }
      <div id="intakeq" styles={{maxWidth: '720px', width: '100%'}}></div>
    </React.Fragment>
  )
}