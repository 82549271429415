// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';

import '@shared/services/sentryHelper';
import defaultTheme from '@shared/constants/theme';
import paymentsTheme from '@shared/constants/paymentsTheme';
// Import root app
import SnackBar from '@shared/components/SnackBar';
import Error from '@shared/components/Error';
import BaseRoutes from './BaseRoutes';

export default function External() {
  const theme = window.location.pathname.startsWith('/payments') ? paymentsTheme : defaultTheme;
  return (
    <Sentry.ErrorBoundary fallback={Error}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <SnackBar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            maxSnack={5}
          >
            <Router>
              <BaseRoutes />
            </Router>
          </SnackBar>
        </ThemeProvider>
      </StyledEngineProvider>
    </Sentry.ErrorBoundary>
  );
}

const appElement = document.getElementById('doctible_react_app');
if (appElement) {
  const root = ReactDOM.createRoot(appElement);
  root.render(<External />);
}
