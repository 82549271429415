import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import ReviewLinks from './ReviewLinks';
import Localize from '../context/Localize';

const styles = theme => ({
  text: {
    fontSize: '14px',
    padding: '30px 0 15px 0',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
    },
  },
});

const visibleReviewSites = (starState, availableReviewSites) => ({
  total: availableReviewSites.filter(obj => obj.site_name !== 'Yelp').length,
  hasGoogle: availableReviewSites.findIndex(obj => obj.site_name === 'Google') >= 0,
});

const displayText = (starState, availableReviewSites) => {
  const reviewSites = visibleReviewSites(starState, availableReviewSites);
  if ((starState > 3 && reviewSites.total > 0) || (starState <= 3 && reviewSites.hasGoogle)) {
    return (
      <Localize.Consumer>
        {(t = {}) => t[`after_links_text_${starState > 3 ? 'good' : 'bad'}`]}
      </Localize.Consumer>
    );
  }
  return (
    <Localize.Consumer>
      {(t = {}) => t[`after_no_links_text_${starState > 3 ? 'good' : 'bad'}`]}
    </Localize.Consumer>
  );
};

const SocialLinks = ({
  classes,
  locale,
  starState,
  availableReviewSites,
  recordReviewSiteClick,
  hideLeaveAMessage,
}) => (
  <React.Fragment>
    <ReviewLinks
      locale={locale}
      recordReviewSiteClick={recordReviewSiteClick}
      availableReviewSites={availableReviewSites}
      starState={starState}
    />
    {(!hideLeaveAMessage || starState < 4) && (
      <div className={classes.text}>{displayText(starState, availableReviewSites)}</div>
    )}
  </React.Fragment>
);

SocialLinks.propTypes = {
  locale: PropTypes.string.isRequired,
  recordReviewSiteClick: PropTypes.func.isRequired, // eslint-disable-line react/forbid-prop-types
  availableReviewSites: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  starState: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  hideLeaveAMessage: PropTypes.bool.isRequired,
};

export default withStyles(styles)(SocialLinks);
