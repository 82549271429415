export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const percentageFormat = (number, options = {}) => new Intl.NumberFormat(
  'en-US',
  { ...{ style: 'percent', maximumFractionDigits: 2 }, ...options },
).format(number);

export const currencyFormat = (number, options = {}) => new Intl.NumberFormat(
  'en-US',
  { ...{ style: 'currency', currency: 'USD', maximumFractionDigits: 0 }, ...options },
).format(number);
