import React, { useContext, useMemo } from 'react';
import moment from 'moment';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { currencyFormat } from '@shared/services/numberHelpers';
import { capitalizeFirstLetter } from '@shared/services/wordHelpers';
import { PaymentContext } from '../../context/PaymentContext';

export default function Invoice() {
  const { invoice, setStep } = useContext(PaymentContext);

  const invoiceStatus = useMemo(() => {
    if (['created', 'sent'].includes(invoice.status)) return 'Pending';
    if (invoice.status === 'partially_paid' && invoice.invoiceType !== 'single_pay') return 'Active';
    return invoice.status.split('_').map((st) => capitalizeFirstLetter(st)).join(' ');
  }, [invoice.status, invoice.amountDue]);

  return (
    <>
      <Box sx={{
        display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%',
      }}
      >
        <Typography variant="h6" sx={{ my: 4 }}>
          { invoice.invoiceType === 'single_pay' ? 'Invoice' : 'Payment Plan' }
        </Typography>
        <Button color="primary" onClick={() => setStep(2)}>
          Back to
          {' '}
          { invoice.invoiceType === 'single_pay' ? 'Payment' : 'Payment Plan' }
        </Button>
      </Box>
      <Box sx={{
        display: 'flex', flexDirection: 'column', gap: 8, mb: 4,
      }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={{
            display: 'flex', flexDirection: 'row', gap: 2, justifyContent: 'space-between',
          }}
          >
            <Box sx={{ flex: 1 }}>
              <Typography>
                Payment Status
              </Typography>
              <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
                {invoiceStatus}
              </Typography>
            </Box>

            { invoice.invoiceType === 'single_pay' && (
              <Box sx={{ flex: 1 }}>
                <Typography>
                  Due Date
                </Typography>
                <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
                  {invoice.dueDate}
                </Typography>
              </Box>
            )}
          </Box>
          <Box>
            <Typography>
              Bill To
            </Typography>
            <Typography variant="h6">
              {invoice.streetAddress}
              <br />
              {invoice.cityStateZip}
              <br />
              {invoice.primaryPhone}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant="h6">
            Service Details
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            <Box sx={{ flex: 1 }}>
              <Typography>
                Date
              </Typography>
              <Typography variant="h6">
                {invoice.serviceDate}
              </Typography>
            </Box>

            <Box sx={{ flex: 1 }}>
              <Typography>
                Patient
              </Typography>
              <Typography variant="h6">
                {invoice.patientName}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography>
              Description
            </Typography>
            <Typography variant="h6">
              {invoice.description}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant="h6">
            Balance
          </Typography>
          <Box sx={{
            display: 'flex', flexDirection: 'row', gap: 4, justifyContent: 'space-between', alignItems: 'center',
          }}
          >
            <Typography>
              Total
              {' '}
              {invoice.timeInterval > 1 ? 'Plan' : 'Invoice'}
              {' '}
              Amount
            </Typography>
            <Typography variant="h6">
              {currencyFormat(invoice.amount, { maximumFractionDigits: 2 })}
            </Typography>
          </Box>
          <Box sx={{
            display: 'flex', flexDirection: 'row', gap: 4, justifyContent: 'space-between', alignItems: 'center',
          }}
          >
            <Typography>
              Amount Paid
            </Typography>
            <Typography variant="h6">
              {currencyFormat(invoice.amountPaid, { maximumFractionDigits: 2 })}
            </Typography>
          </Box>
          { invoice.amountRefunded > 0 && (
            <Box sx={{
              display: 'flex', flexDirection: 'row', gap: 4, justifyContent: 'space-between', alignItems: 'center',
            }}
            >
              <Typography>
                Amount Refunded
              </Typography>
              <Typography variant="h6">
                {currencyFormat(invoice.amountRefunded, { maximumFractionDigits: 2 })}
              </Typography>
            </Box>
          )}
          <Box sx={{
            display: 'flex', flexDirection: 'row', gap: 4, justifyContent: 'space-between', alignItems: 'center',
          }}
          >
            <Typography>
              Amount Due
              {' '}
              { invoice.timeInterval > 1 && 'This month' }
            </Typography>
            <Typography variant="h6">
              {currencyFormat(invoice.amountDue, { maximumFractionDigits: 2 })}
            </Typography>
          </Box>
          <Box sx={{
            display: 'flex', flexDirection: 'row', gap: 4, justifyContent: 'space-between', alignItems: 'center',
          }}
          >
            <Typography>
              {invoice.timeInterval > 1 ? 'Total Amount Due' : 'Total Patient Balance'}
            </Typography>
            <Typography variant="h6">
              {currencyFormat(invoice.totalDue, { maximumFractionDigits: 2 })}
            </Typography>
          </Box>
        </Box>

        { invoice.invoiceType !== 'single_pay' && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <Typography variant="h6">Payment Plan Schedule:</Typography>
            { Array.from({ length: invoice.timeInterval }, (_, i) => {
              const date = moment(invoice.startDate).add(i, 'months');
              const amount = i === (invoice.timeInterval - 1) ? (invoice.amount - (invoice.paymentAmount * i)) : invoice.paymentAmount;
              return (
                <Box key={i} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography>
                    {date.format('MM/DD/YY')}
                  </Typography>
                  <Typography>
                    {currencyFormat(amount, { maximumFractionDigits: 2 })}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    </>
  );
}
