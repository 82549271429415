import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import withStyles from '@mui/styles/withStyles';

import request from '@shared/services/api/requests';
import AppointmentParser from '../../services/AppointmentParserV2';
import AlignCenter from '../../components/AlignCenter';

import ConfirmApptsSection from './components/ConfirmAppts';
import LinkExpired from './components/LinkExpired';
import Header from './components/Header';
import Footer from './components/Footer';


const styles = {
  wrapper: {
    fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
  },
};

export class Appointments extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    match: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  }

  constructor(props) {
    super(props);
    const data = JSON.parse(document.getElementById('reactData').innerHTML);

    if (data.expired) {
      this.state = {
        data,
      };
      return;
    }

    const apptParser = AppointmentParser({
      appts: data.appointments,
      timeZone: data.time_zone,
    });

    this.state = {
      data,
      isSubmitting: false,
      hasConfirmed: false,
      hasErrors: false,
      appts: apptParser.get({ groupBy: 'patient' }),
      checkboxState: this.getCheckboxStateFromAppts(apptParser.get({ confirmed: false })),
    };
  }

  getCheckboxStateFromAppts = (appts) => {
    const checkboxState = {};
    appts.forEach((appt) => {
      checkboxState[appt.id] = false;
    });
    return checkboxState;
  }

  updateCheckboxState = appId => () => {
    if (typeof this.state.checkboxState[appId] === 'undefined') {
      return;
    }
    const checkboxState = {
      ...this.state.checkboxState,
      [appId]: !this.state.checkboxState[appId],
    };
    this.setState({
      checkboxState,
    });
  }

  updateParentState = (state) => {
    this.setState(state);
  };

  checkedAppts = () => {
    const checkedAppts = [];
    Object.keys(this.state.checkboxState).forEach((apptId) => {
      if (this.state.checkboxState[apptId] === true) {
        checkedAppts.push(apptId);
      }
    });
    return checkedAppts;
  };

  submit = async () => {
    const checkedAppts = this.checkedAppts();
    if (checkedAppts.length === 0) {
      return true;
    }
    this.setState({
      isSubmitting: true,
    });
    const queryString = window.location.search;
    const data = await request({
      url: this.props.match.url + queryString,
      data: { confirm_appointments: checkedAppts },
    });
    if (data.error) {
      this.setState({
        isSubmitting: false,
        hasConfirmed: false,
        hasErrors: true,
      });
      return false;
    }
    const apptParser = AppointmentParser({
      appts: data.appointments,
      timeZone: data.time_zone,
    });
    this.setState({
      isSubmitting: false,
      hasConfirmed: true,
      hasErrors: false,
      appts: apptParser.get({ groupBy: 'patient' }),
      checkboxState: this.getCheckboxStateFromAppts(apptParser.get({ confirmed: false })),
    });
    return true;
  };

  render() {
    return (
      <div className={this.props.classes.wrapper}>
        <CssBaseline />
        <AlignCenter>
          <Header data={this.state.data} />
          {
            this.state.data.expired ?
              <LinkExpired data={this.state.data} />
              :
              <ConfirmApptsSection
                max_days_ahead={this.state.data.max_days_ahead}
                appts={this.state.appts}
                data={this.state.data}
                checkboxState={this.state.checkboxState}
                updateCheckboxState={this.updateCheckboxState}
                isSubmitting={this.state.isSubmitting}
                hasConfirmed={this.state.hasConfirmed}
                hasErrors={this.state.hasErrors}
                updateParentState={this.updateParentState}
                checkedAppts={this.checkedAppts}
                submit={this.submit}
              />
          }
          <Footer data={this.state.data} />
        </AlignCenter>
      </div>
    );
  }
}

export default withStyles(styles)(Appointments);
