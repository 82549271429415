import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import request from '@shared/services/api/requests';
import YesNo from './YesNo';
import StarRating from './StarRating';
import Review from './Review';
import FeedbackForm from './FeedbackForm';
import ThankYou from './ThankYou';

class FilterExperience extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  constructor(props) {
    super(props);
    const data = JSON.parse(document.getElementById('reactData').innerHTML);

    this.state = {
      locale: data.locale ? data.locale : 'en',
      universal: data.universal,
      askRating: data.ask_rating,
      hideLeaveAMessage: data.hide_leave_a_message,
      isSubmitting: false,
      starState: data.review_request.rating ? data.review_request.rating : 0,
      ratingSubmitted: !!data.review_request.rating,
      feedbackSubmitted: !!data.review_request.message,
      showFeedbackForm: false,
      availableReviewSites: data.available_review_sites,
      token: data.universal ? null : props.match.params.token,
    };
  }

  backButton = () => {
    if (this.state.showFeedbackForm) {
      this.toggleFeedbackForm();
      return true;
    }
    this.setState({
      ratingSubmitted: false,
    });
    return true;
  };

  toggleFeedbackForm = () => {
    this.setState({
      showFeedbackForm: !this.state.showFeedbackForm,
    });
    return true;
  };

  updateStarState = (value) => {
    this.setState({
      starState: value,
      ratingSubmitted: !!this.state.universal,
    });
    return true;
  };

  submitStar = async (rating) => {
    this.setState({
      isSubmitting: true,
      starState: rating,
    });
    const data = await request({
      url: this.props.match.url,
      method: 'PUT',
      data: {
        token: this.state.token,
        rating,
      },
    });
    if (data.error) {
      this.setState({
        isSubmitting: false,
        ratingSubmitted: false,
      });
      return false;
    }
    this.setState({
      isSubmitting: false,
      starState: rating,
      ratingSubmitted: true,
    });
    return true;
  };

  submitFeedback = async (values) => {
    this.setState({
      isSubmitting: true,
    });
    let data = {
      token: this.state.token,
      rating: this.state.starState,
      message: values.message,
    };
    if (this.state.universal) {
      data = {
        ...data,
        name: values.name,
        email: values.email,
      };
    }
    const response = await request({
      url: this.props.match.url,
      method: 'PUT',
      data,
    });
    if (response.error) {
      this.setState({
        isSubmitting: false,
        feedbackSubmitted: false,
      });
      return false;
    }
    this.setState({
      token: response && response.token ? response.token : this.state.token,
      isSubmitting: false,
      feedbackSubmitted: true,
      showFeedbackForm: false,
    });
    return true;
  };

  recordReviewSiteClick = async (locationReviewSiteId, siteName) => {
    const response = await request({
      url: '/api/review_requests/review_site_clicked',
      method: 'POST',
      data: {
        token: this.state.token,
        location_review_site_id: locationReviewSiteId,
        site_name: siteName,
        rating: this.state.starState,
      },
    });
    if (response && response.token) {
      this.setState({
        token: response.token,
      });
    }
    return true;
  };

  render() {
    return (
      <React.Fragment>
        {!this.state.ratingSubmitted &&
          !this.state.feedbackSubmitted &&
          !this.state.showFeedbackForm &&
          this.state.askRating && (
            <StarRating
              locale={this.state.locale}
              universal={this.state.universal}
              updateStarState={this.updateStarState}
              starState={this.state.starState}
              isSubmitting={this.state.isSubmitting}
              submit={this.submitStar}
              availableReviewSites={this.state.availableReviewSites}
            />
          )}
        {!this.state.ratingSubmitted &&
          !this.state.feedbackSubmitted &&
          !this.state.showFeedbackForm &&
          !this.state.askRating && (
            <YesNo
              locale={this.state.locale}
              universal={this.state.universal}
              updateStarState={this.updateStarState}
              starState={this.state.starState}
              isSubmitting={this.state.isSubmitting}
              submit={this.submitStar}
              availableReviewSites={this.state.availableReviewSites}
            />
          )}
        {this.state.ratingSubmitted &&
         !this.state.feedbackSubmitted &&
         !this.state.showFeedbackForm &&
         this.state.availableReviewSites.length !== 0 && (
           <Review
             locale={this.state.locale}
             universal={this.state.universal}
             starState={this.state.starState}
             isSubmitting={this.state.isSubmitting}
             submit={this.submitFeedback}
             recordReviewSiteClick={this.recordReviewSiteClick}
             backButton={this.backButton}
             availableReviewSites={this.state.availableReviewSites}
             toggleFeedbackForm={this.toggleFeedbackForm}
             hideLeaveAMessage={this.state.hideLeaveAMessage}
           />
         )}
        {(this.state.showFeedbackForm ||
        (this.state.ratingSubmitted && this.state.availableReviewSites.length === 0)) && (
          <FeedbackForm
            universal={this.state.universal}
            backButton={this.backButton}
            starState={this.state.starState}
            isSubmitting={this.state.isSubmitting}
            submit={this.submitFeedback}
            isFilterExperience
          />
        )}
        {this.state.ratingSubmitted && this.state.feedbackSubmitted && (
          <ThankYou starState={this.state.starState} />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(FilterExperience);
