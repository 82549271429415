import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';


const styles = {
  wrapper: {
    fontFamily: 'Roboto, "Helvetica Neue",Helvetica,Arial,sans-serif',
    marginTop: '25px',
    color: '#444444',
  },
};

const Wrapper = props => (
  <div className={props.classes.wrapper}>
    {props.children}
  </div>
);

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(Wrapper);
