import React from 'react';
import PropTypes from 'prop-types';


export default (WrappedComponent) => {
  const Wrapped = (props) => {
    const handleClick = () => props.handleClick(props.data);
    const passedProps = {
      ...props,
    };
    delete passedProps.handleClick;
    delete passedProps.data;
    return (
      <WrappedComponent
        {...passedProps}
        onClick={handleClick}
      >
        {props.children}
      </WrappedComponent>
    );
  };
  Wrapped.propTypes = {
    handleClick: PropTypes.func.isRequired, // eslint-disable-line react/forbid-prop-types
    data: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    children: PropTypes.node, // eslint-disable-line react/forbid-prop-types
  };
  Wrapped.defaultProps = {
    data: {},
    children: null,
  };
  return Wrapped;
};
