import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import SocialLinks from './SocialLinks';
import Localize from '../context/Localize';

const styles = theme => ({
  wrapper: {
    textAlign: 'center',
  },
  headlineText: {
    fontSize: '21px',
    fontWeight: '500',
    padding: '25px 0 10px 0',
    [theme.breakpoints.up('md')]: {
      fontSize: '40px',
      padding: '25px 0 10px 0',
    },
  },
  backButtonWrapper: {
    textAlign: 'left',
    marginLeft: '-10px',
  },
  backButton: {
    textTransform: 'none',
    color: '#3678BD',
    padding: '0 10px 0 0',
    fontSize: '14px',
    minWidth: '0',
    minHeight: '0',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
    },
  },
  feedbackButton: {
    marginTop: '5px',
    textTransform: 'none',
    fontSize: '14px',
    backgroundColor: '#eeeeee',
    boxShadow: 'none',
    color: '#444444',
    '&:hover': {
      backgroundColor: '#dddddd',
    },
    '&:focused': {
      backgroundColor: '#dddddd',
    },
    '&:active': {
      backgroundColor: '#dddddd',
    },
    '&:visited': {
      backgroundColor: '#dddddd',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
    },
  },
});

class Review extends React.Component {
  static propTypes = {
    universal: PropTypes.bool,
    locale: PropTypes.string.isRequired,
    availableReviewSites: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    starState: PropTypes.number.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    recordReviewSiteClick: PropTypes.func.isRequired, // eslint-disable-line react/forbid-prop-types
    backButton: PropTypes.func.isRequired, // eslint-disable-line react/forbid-prop-types
    toggleFeedbackForm: PropTypes.func.isRequired, // eslint-disable-line react/forbid-prop-types
    submit: PropTypes.func.isRequired, // eslint-disable-line react/forbid-prop-types
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    hideLeaveAMessage: PropTypes.bool.isRequired,
  };
  static defaultProps = {
    universal: false,
  };

  state = {
    reviewText: '',
  };

  handleChange = name => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  submit = () => {
    this.props.submit({ message: this.state.reviewText });
  };

  render() {
    const { classes } = this.props;
    return (
      <Localize.Consumer>
        {(t = {}) => (
          <div className={classes.wrapper}>
            <div className={classes.backButtonWrapper}>
              <Button
                className={classes.backButton}
                onClick={this.props.backButton}
                data-testid="review-request-back-button"
              >
                <KeyboardArrowLeftIcon />
                {/* i18n-tasks-use t('base_react.no_filter_review_request.go_back') */}
                {t.go_back}
              </Button>
            </div>
            <div className={classes.headlineText}>
              {t[`review_headline_message_${this.props.starState > 3 ? 'good' : 'bad'}`]}
            </div>
            <SocialLinks
              locale={this.props.locale}
              starState={this.props.starState}
              availableReviewSites={this.props.availableReviewSites}
              recordReviewSiteClick={this.props.recordReviewSiteClick}
              hideLeaveAMessage={this.props.hideLeaveAMessage}
            />
            {(!this.props.hideLeaveAMessage || this.props.starState < 4) && (
              <Button
                variant="contained"
                classes={{ root: classes.feedbackButton }}
                onClick={this.props.toggleFeedbackForm}
                data-testid="feedback-button"
              >
                {t.contact_us_directly}
              </Button>
            )}
          </div>
        )}
      </Localize.Consumer>
    );
  }
}

export default withStyles(styles)(Review);
