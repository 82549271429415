/* eslint-disable max-len */
import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

export default function SinglePatientRequest({
  patientsToConfirm, fastFill, easyFillRequest, handleCodeConfirmationToggle,
}) {
  const efRequestPatient = easyFillRequest.patientsWithSameContact[0];

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!efRequestPatient) return <></>;

  return (
    <Tooltip
      title="Confirm availability"
      placement="top"
      disableInteractive
    >
      <FormControlLabel
        control={fastFill
          ? (
            <Radio
              checked={patientsToConfirm.findIndex((patientToConfirm) => patientToConfirm.code === efRequestPatient.code) !== -1}
              onChange={() => handleCodeConfirmationToggle(efRequestPatient)}
            />
          ) : (
            <Checkbox
              checked={patientsToConfirm.findIndex((patientToConfirm) => patientToConfirm.code === efRequestPatient.code) !== -1 || efRequestPatient.status === 'confirmed_availability'}
              disabled={efRequestPatient.status === 'confirmed_availability'}
              onChange={() => handleCodeConfirmationToggle(efRequestPatient)}
            />
          )}
        label={(
          <Typography variant="subtitle1" align="center" style={{ marginBottom: 16 }}>
            {easyFillRequest.weekday}
            ,
            {' '}
            {easyFillRequest.formattedDate}
            {' '}
            at
            {' '}
            {easyFillRequest.formattedTime}
          </Typography>
        )}
      />
    </Tooltip>
  );
}
