import React from 'react';
import { Route, Switch } from 'react-router-dom';

import NotFound from '@shared/components/NotFound';
import Survey from './screens/Survey';
import BirthdayVerification from './screens/BirthdayVerification';
import ContactsAppointments from './screens/ContactsAppointments';
import MessagingOptIn from './screens/MessagingOptIn';
import FormCompleted from './screens/FormCompleted';
import ReviewRequests from './screens/ReviewRequests';
import CsdValidation from './screens/CsdValidation';
import SmartSchedule from './screens/SmartSchedule';
import SmartScheduleTestScript from './screens/SmartSchedule/TestScript';
import EasyFill from './screens/EasyFill';
import Payments from './screens/Payments';

function BaseRoutes() {
  return (
    <Switch>
      <Route path="/payment-verify-birthday/:id" component={BirthdayVerification} />
      <Route path="/contacts_appointments/:id" component={ContactsAppointments} />
      <Route path="/pr/:id" component={ContactsAppointments} />
      <Route path="/sm/:id" component={ContactsAppointments} />
      <Route path="/messaging_opt_in/:customUrlName" component={MessagingOptIn} />
      <Route path="/locations/:id/completed_form" component={FormCompleted} />
      <Route path="/review_requests/universal/:location" component={ReviewRequests} />
      <Route path="/review_requests/:token" component={ReviewRequests} />
      <Route path="/rr/:token" component={ReviewRequests} />
      <Route path="/vwr/:short_id" component={Survey} />
      <Route path="/csd_validation" component={CsdValidation} />
      <Route path="/practice/schedule/test_script" component={SmartScheduleTestScript} />
      <Route path="/practice/schedule/:customUrlName/:iframe" component={SmartSchedule} />
      <Route path="/practice/schedule/:customUrlName" component={SmartSchedule} />
      <Route path="/ef/:token" component={EasyFill} />
      <Route path="/payments/:token" component={Payments} />
      <Route path="/" component={NotFound} />
    </Switch>
  );
}

export default BaseRoutes;
