import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';

import ReviewLinksGreen from './ReviewLinksGreen';
import Localize from '../context/Localize';

const styles = theme => ({
  wrapper: {
    textAlign: 'center',
  },
  headlineText: {
    fontSize: '21px',
    fontWeight: '500',
    padding: '15px 0 10px 0',
    [theme.breakpoints.up('md')]: {
      fontSize: '40px',
      padding: '25px 0 10px 0',
    },
  },
  headlineSubText: {
    fontSize: '16px',
    padding: '10px 40px',
  },
  badContactUs: {
    fontSize: '16px',
    padding: '10px 40px',
    [theme.breakpoints.up('md')]: {
      padding: '30px 40px',
    },
  },
  feedbackButton: {
    width: '94%',
    height: '50px',
    marginTop: '5px',
    textTransform: 'none',
    fontSize: '18px',
    backgroundColor: '#e53935',
    boxShadow: 'none',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#EE494E',
    },
    '&:focused': {
      backgroundColor: '#EE494E',
    },
    '&:active': {
      backgroundColor: '#EE494E',
    },
    '&:visited': {
      backgroundColor: '#EE494E',
    },
    [theme.breakpoints.up('md')]: {
      width: '90%',
      height: '73px',
      fontSize: '25px',
    },
  },
});

function NoFilterReview(props) {
  const { classes } = props;
  function contactUs() {
    props.history.push(`${props.location.pathname}/feedback`);
  }
  return (
    <Localize.Consumer>
      {(t = {}) => (
        <div className={classes.wrapper}>
          {/* i18n-tasks-use t('base_react.no_filter_review_request.how_was_your_experience') */}
          <div className={classes.headlineText}>{t.how_was_your_experience}</div>
          {/*i18n-tasks-use t('base_react.no_filter_review_request.review_ask') */}
          <div className={classes.headlineSubText}>{t.review_ask}</div>
          <ReviewLinksGreen
            locale={props.locale}
            availableReviewSites={props.availableReviewSites}
            recordReviewSiteClick={!props.universal ? props.recordReviewSiteClick : () => {}}
          />
          {/*i18n-tasks-use t('base_react.no_filter_review_request.bad_experience')*/}
          <div className={classes.badContactUs}>{t.bad_experience}</div>
          <Button
            variant="contained"
            classes={{ root: classes.feedbackButton }}
            onClick={contactUs}
            data-testid="filter-feedback-button"
          >
          {/*i18n-tasks-use t('base_react.no_filter_review_request.contact_us_directly')*/}
            {t.contact_us_directly}
          </Button>
        </div>
      )}
    </Localize.Consumer>
  );
}
NoFilterReview.propTypes = {
  locale: PropTypes.string.isRequired,
  availableReviewSites: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(withRouter(NoFilterReview));
