import Facebook from '@shared/components/images/Facebook.svg';
import Google from '@shared/components/images/Google.svg';
import Yelp from '@shared/components/images/Yelp.svg';
import Doctible from '@shared/components/images/Doctible.svg';
import Citysearch from '@shared/components/images/citysearch.svg';
import YahooLocal from '@shared/components/images/yahoo_local.svg';
import Yellowpages from '@shared/components/images/yellowpages.svg';
import YellowBook from '@shared/components/images/yellow_book.svg';
import RateMds from '@shared/components/images/rate_mds.svg';
import Vitals from '@shared/components/images/vitals.svg';
import MyListing from '@shared/components/images/my_listing.svg';
import Healthgrades from '@shared/components/images/healthgrades.svg';
import Zocdoc from '@shared/components/images/zocdoc.svg';
import AngiesList from '@shared/components/images/angies-list.svg';
import BBB from '@shared/components/images/bbb.svg';
import RealSelf from '@shared/components/images/realself.svg';
import HealthyHearing from '@shared/components/images/healthy_hearing.svg';
import DoctorCom from '@shared/components/images/doctor_com.svg';

export const socialImageByName = {
  Facebook,
  Google,
  Yelp,
  doctible: Doctible,
  citysearch: Citysearch,
  'yahoo!_local': YahooLocal,
  'Yellow Pages': Yellowpages,
  yellow_book: YellowBook,
  RateMDs: RateMds,
  Vitals,
  my_listing: MyListing,
  HealthGrades: Healthgrades,
  Zocdoc,
  "Angie's List": AngiesList,
  'Better Business Bureau': BBB,
  RealSelf,
  'Healthy Hearing': HealthyHearing,
  'Doctor.Com': DoctorCom
};
