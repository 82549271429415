import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import Localize from '../context/Localize';

const styles = theme => ({
  wrapper: {
    textAlign: 'center',
  },
  headlineText: {
    fontSize: '21px',
    fontWeight: '500',
    padding: '25px 0 0 0',
    [theme.breakpoints.up('md')]: {
      fontSize: '30px',
    },
  },
  subText: {
    fontSize: '14px',
    padding: '15px 0 5px 0',
    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
    },
  },
});

const ThankYou = ({ classes, starState }) => (
  <Localize.Consumer>
    {(t = {}) => (
      <div className={classes.wrapper}>
        <div className={classes.headlineText}>
          {t[`thank_you_headline_${starState > 3 ? 'good' : 'bad'}`]}
        </div>
        {starState > 3 && <div className={classes.subText}>{t.thank_you_sub_text_good}</div>}
      </div>
    )}
  </Localize.Consumer>
);

ThankYou.propTypes = {
  starState: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(ThankYou);
