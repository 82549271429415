import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';

import { socialImageByName } from './reviewSiteLogos';
import Localize from '../context/Localize';

const styles = theme => ({
  link: {
    marginTop: '15px',
  },
  img: {
    width: '34px',
    margin: '5px 0 0 5px',
    [theme.breakpoints.up('md')]: {
      width: '44px',
      margin: '10px 0 0 10px',
    },
  },
  reviewLinksButton: {
    width: '94%',
    height: '50px',
    marginTop: '4px',
    marginBottom: '4px',
    textTransform: 'none',
    fontSize: '16px',
    backgroundColor: '#87be45',
    boxShadow: 'none',
    textAlign: 'left',
    justifyContent: 'left',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#87be45',
    },
    '&:focused': {
      backgroundColor: '#87be45',
    },
    '&:active': {
      backgroundColor: '#87be45',
    },
    '&:visited': {
      backgroundColor: '#87be45',
    },
    [theme.breakpoints.up('md')]: {
      width: '90%',
      height: '73px',
      fontSize: '25px',
    },
  },
  imageBox: {
    backgroundColor: '#fff',
    height: '44px',
    width: '44px',
    marginLeft: '-13px',
    borderRadius: '2px',
    display: 'inline-block',
    flexShrink: '0',
    [theme.breakpoints.up('md')]: {
      height: '65px',
      width: '65px',
      marginLeft: '-12px',
    },
  },
  reviewLinkText: {
    fontSize: '23px',
    marginLeft: '30px',
    textAlign: 'center',
    display: 'inline-block',
    lineHeight: '20px',
    flexGrow: '1',
    [theme.breakpoints.up('md')]: {
      marginLeft: '30px',
      lineHeight: '30px',
    },
  },
});

const positionSort = (a, b) => a.position - b.position;

const ReviewLinks = ({ classes, availableReviewSites, recordReviewSiteClick }) => {
  const orderedAvailableReviewSites = availableReviewSites.sort(positionSort);
  const recordClick = (locationReviewSiteId, siteName) => {
    recordReviewSiteClick(locationReviewSiteId, siteName);
  };
  return (
    <Localize.Consumer>
      {(t = {}) => (
        <React.Fragment>
          {orderedAvailableReviewSites.map((site) => {
            if (!socialImageByName[site.site_name]) return null;
            if (site.site_name === 'Yelp') return null;
            return (
              <Button
                key={site.id}
                fullWidth
                variant="contained"
                classes={{ root: classes.reviewLinksButton }}
                onClick={() => recordClick(site.id, site.site_name)}
                href={site.url}
                target="_blank" // eslint-disable-line react/jsx-no-target-blank
                component="a"
              >
                <span className={classes.imageBox}>
                  <img className={classes.img} src={socialImageByName[site.site_name]} alt="" />
                </span>
                <span className={classes.reviewLinkText}>
                  {t.review_us_on} {site.site_name}
                </span>
              </Button>
            );
          })}
        </React.Fragment>
      )}
    </Localize.Consumer>
  );
};

ReviewLinks.propTypes = {
  recordReviewSiteClick: PropTypes.func, // eslint-disable-line react/forbid-prop-types
  availableReviewSites: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

ReviewLinks.defaultProps = {
  recordReviewSiteClick: () => {},
};
export default withStyles(styles)(ReviewLinks);
