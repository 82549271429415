/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSnackbar } from 'notistack';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';

import request from '@shared/services/api/requests';
import AddressDisplay from '../../components/AddressDisplay';
import PhoneLink from '../../components/PhoneLink';
import SinglePatientRequest from './SinglePatientRequest';
import MultiPatientRequests from './MultiPatientRequests';

const useStyles = makeStyles((theme) => ({
  img: {
    maxHeight: 60,
    maxWidth: 200,
  },
  paper: {
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '80%',
    margin: '0 auto',
    boxShadow: '0px 4px 20px rgb(0 0 0 / 20%)',
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  footer: {
    fontSize: 14,
    textAlign: 'center',
  },
}));

export default function EasyFill({ match }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [easyFillRequests, setEasyFillRequests] = React.useState([]);
  const [fastFill, setFastFill] = React.useState(false);
  const [location, setLocation] = React.useState(null);
  const [patientsToConfirm, setPatientsToConfirm] = React.useState([]);
  const [multiPatients, setMultiPatients] = React.useState(false);
  const [confirmationSuccessful, setConfirmationSuccessful] = React.useState(false);

  React.useEffect(() => {
    const fetchEasyFillRequestPatient = async () => {
      setLoading(true);
      const response = await request({
        url: match.url,
        method: 'GET',
      });
      if (response.error) {
        enqueueSnackbar('Error trying to find request', { variant: 'error' });
      } else {
        setEasyFillRequests(response.easyFillRequests);
        setLocation(response.location);
        setMultiPatients(response.multiPatients); // whether or not there are more than one patient with the given contact
      }
      setLoading(false);
    };
    fetchEasyFillRequestPatient();
  }, []);

  React.useEffect(() => {
    setFastFill(easyFillRequests.some((easyFillRequest) => easyFillRequest.fastFill));
  }, [easyFillRequests]);

  const handleConfirmationResponse = (response) => {
    if (response.error) {
      enqueueSnackbar('Error trying to confirm availability', { variant: 'error' });
      return false;
    }
    enqueueSnackbar('Availability confirmed!', { variant: 'success' });
    setConfirmationSuccessful(true);
    setEasyFillRequests(response.easyFillRequests);
    setLocation(response.location);
    setMultiPatients(response.multiPatients);
    setPatientsToConfirm([]);
    return true;
  };

  const handleConfirm = async () => {
    const response = await request({
      url: `${match.url}/confirm`,
      method: 'POST',
      data: {
        codes: patientsToConfirm.map((easyFillRequestPatient) => easyFillRequestPatient.code),
      },
    });
    handleConfirmationResponse(response);
  };

  const handleCodeConfirmationToggle = (easyFillRequestPatient) => {
    if (fastFill) {
      const patientIndex = patientsToConfirm.findIndex((patientToConfirm) => patientToConfirm.patientId === easyFillRequestPatient.patientId);
      const requestIndex = patientsToConfirm.findIndex((patientToConfirm) => patientToConfirm.easyFillRequestId === easyFillRequestPatient.easyFillRequestId);
      if (patientIndex === -1 && requestIndex === -1) {
        // patient wasn't previously selected
        setPatientsToConfirm([...patientsToConfirm, easyFillRequestPatient]);
      } else {
        // replace previous selection with current one
        const confirmationsWithoutPreviousSelections = patientsToConfirm.filter((_, index) => ![patientIndex, requestIndex].includes(index));
        setPatientsToConfirm([...confirmationsWithoutPreviousSelections, easyFillRequestPatient]);
      }
    } else {
      const confirmationsWithoutPatient = patientsToConfirm.filter((patientToConfirm) => patientToConfirm.code !== easyFillRequestPatient.code);
      if (confirmationsWithoutPatient.length === patientsToConfirm.length) {
        // patient wasn't previously selected
        setPatientsToConfirm([...patientsToConfirm, easyFillRequestPatient]);
      } else {
        // patient was unselected
        setPatientsToConfirm(confirmationsWithoutPatient);
      }
    }
  };

  if (loading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" mt={3}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (easyFillRequests.length === 0) {
    return (
      <Container>
        <Box my={2} textAlign="center">
          { confirmationSuccessful ? (
            <Typography>Your appointment time has been approved! Someone will be following up shortly with more details.</Typography>
          ) : (
            <Typography>The availability is no longer available.</Typography>
          )}
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <CssBaseline />
      {location.logoUrl && (
        <Box my={2} display="flex" justifyContent="center">
          <img
            className={classes.img}
            src={location.logoUrl}
            alt={location.name}
          />
        </Box>
      )}
      <Box my={2} textAlign="center">
        <Typography>
          {location.name}
          {' '}
          | New Appointment
          { easyFillRequests.length === 1 ? ' Availability' : ' Availabilities' }
          !
        </Typography>
      </Box>
      <Paper className={classes.paper}>
        { easyFillRequests.map((easyFillRequest) => (
          <Box key={`easy-fill-request-${easyFillRequest.id}`}>
            { !multiPatients ? (
              <SinglePatientRequest
                patientsToConfirm={patientsToConfirm}
                fastFill={fastFill}
                easyFillRequest={easyFillRequest}
                handleCodeConfirmationToggle={handleCodeConfirmationToggle}
              />
            ) : (
              <MultiPatientRequests
                patientsToConfirm={patientsToConfirm}
                fastFill={fastFill}
                easyFillRequest={easyFillRequest}
                handleCodeConfirmationToggle={handleCodeConfirmationToggle}
              />
            )}
          </Box>
        ))}
        { patientsToConfirm.length > 0 && (
          <Button variant="contained" onClick={handleConfirm} fullWidth>
            Confirm
            { patientsToConfirm.length === 1 ? ' Availability' : ' Availabilities' }
          </Button>
        )}
      </Paper>
      <Box mt={3} className={classes.footer}>
        <AddressDisplay
          {...location}
          address_1={location.address1}
          address_2={location.address2}
        />
        <p style={{ margin: 0 }}>
          Questions? Call us at
          {' '}
          <PhoneLink number={location.primaryPhone} />
        </p>
      </Box>
    </Container>
  );
}
