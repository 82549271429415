import React from 'react';
import styled, { css } from 'styled-components';
import { FormGroup, FormControl, Col } from 'react-bootstrap';

const sizes = {
  giant: 1199,
  desktop: 991,
  tablet: 767,
  phone: 575,
};

// iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((accumulator, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  const emSize = sizes[label] / 16;
  accumulator[label] = (...args) => css`
    @media (max-width: ${emSize}em) {
      ${css(...args)}
    }
  `;
  return accumulator
}, {});

export function truncate(width) {
  return `
    width: ${width};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
}

export const HeaderText = styled.div`
  margin:20px 0 0 0;
  text-align:center;
  color: #000000;
  font-size:18px;
`;

export const FormWrapper = styled.div`
  width:442px;
  margin:auto;
  ${media.phone`width:302px;`}
`;

export const ColCenter = styled(Col)`
  text-align:center;
`;

export const FormLabel = styled.div`
  font-size:18px;
  font-weight:normal;
  margin:75px 0 5px 0;
  text-align:left;
`;

export const FormGroupWrapper = styled(({ margin, ...rest }) => <FormGroup {...rest} />)`
  margin: ${props => props.margin ? props.margin : '0 18px 0 0'};
  display: inline-block !important;
  ${media.phone`
    margin: ${props => props.margin ? props.margin : '0 7px 0 0'};
  `}

  &:last-child {
    margin:20px 0 0 0;
  }
`;

export const FormControlInput = styled(({ error, ...rest }) => <FormControl {...rest} variant="standard" />)`
  width:135px !important;
  height:75px !important;
  text-align:center;
  line-height:75px;
  font-size:24px;
  border-radius:0;
  border-color:#cccccc;
  border-width: ${props => props.error ? '2px' : '1px'};
  ${media.phone`
    width:96px !important;
    height:75px !important;
  `}
`;

export const FormButton = styled.button`
  width:440px;
  height:75px;
  line-height:75px;
  font-size:24px;
  color:#ffffff;
  background-color:#02BF02;
  display:inline-block;
  white-space:nowrap;
  border-radius:2px;
  border:0;
  font-weight:normal;
  ${media.phone`
    width:302px;
    height:75px;
    line-height:75px;
    font-size:20px;
  `}
`;

export const FooterText = styled.div`
  text-align:center;
  color:#000000;
  font-size:18px;
  margin-top:60px;
  ${media.tablet`font-size:14px;`}
  ${media.phone`font-size:12px;`}
`;
