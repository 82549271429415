import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import { phoneFormatter } from '@shared/services/phoneHelper';

const styles = {
  link: {
    color: 'inherit',
    fontSize: 'inherit',
    display: 'inline-block',
    textDecoration: 'underline',
    '&:link': {
      color: 'inherit',
      textDecoration: 'underline',
    },
    '&:visited': {
      color: 'inherit',
      textDecoration: 'underline',
    },
    '&:hover': {
      color: 'inherit',
      textDecoration: 'underline',
    },
    '&:active': {
      color: 'inherit',
      textDecoration: 'underline',
    },
  },
};

const PhoneLink = props => (
  <a
    className={props.classes.link}
    href={`tel:${props.number}`}
  >
    {phoneFormatter(props.number)}
  </a>
);

PhoneLink.propTypes = {
  number: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(PhoneLink);
