import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import PhoneLink from '../../../components/PhoneLink';

const styles = {
  expiredContainer: {
    textAlign: 'center',
  },
  expiredText: {
    fontWeight: 'bold',
    fontSize: '24px',
    margin: '40px 0',
  },
  phone: {
    fontWeight: 'bold',
    fontSize: '18px',
    margin: '40px 0',
    textAlign: 'center',
  },
};

const LinkExpired = ({ classes, data }) => (
  <div className={classes.expiredContainer}>
    <div className={classes.expiredText}>
      For security purposes,<br />this link has expired
    </div>
    <div className={classes.phone}>
      If you need to reach us call<br />
      <PhoneLink number={data.location.primary_phone} />
    </div>
  </div>
);

LinkExpired.propTypes = {
  data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(LinkExpired);
