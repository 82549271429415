/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import currentLocation from '@practice_users/services/locationHelper';
import View from './components/View';
import { PaymentProvider } from './context/PaymentContext';

import AddressDisplay from '../../components/AddressDisplay';
import PhoneLink from '../../components/PhoneLink';
import PoweredByDoctible from '../../components/PoweredByDoctible';

export default function Payments() {
  return (
    <>
      <CssBaseline />
      <PaymentProvider>
        <Container sx={{ minHeight: '100dvh' }}>
          <View />
          <Box sx={{
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '100%', my: 4,
          }}
          >
            <AddressDisplay
              {...currentLocation}
              address_1={currentLocation.address1}
              address_2={currentLocation.address2}
            />
            <p style={{ margin: 0 }}>
              Questions? Call us at
              {' '}
              <PhoneLink number={currentLocation.primaryPhone} />
            </p>
            <p style={{ margin: 0 }}>
              <PoweredByDoctible />
            </p>
          </Box>
        </Container>
      </PaymentProvider>
    </>
  );
}
