import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import AddressDisplay from '../../../components/AddressDisplay';
import PhoneLink from '../../../components/PhoneLink';

const styles = {
  wrapper: {
    textAlign: 'center',
    fontSize: '15px',
  },
  rescheduleText: {
    width: '300px',
    fontWeight: '500',
    textAlign: 'center',
    padding: '30px 0 20px 0',
  },
};

const Footer = ({ data, classes }) => (
  <div className={classes.wrapper}>
    {
      data.appointments &&
      data.appointments.length !== 0 &&
      !data.expired &&
      <div className={classes.rescheduleText}>
        If you need to reschedule,<br />
        call us at <PhoneLink number={data.location.primary_phone} />
      </div>
    }
    <AddressDisplay {...data.location} />
  </div>
);

Footer.propTypes = {
  classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles)(Footer);
