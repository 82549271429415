/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSnackbar } from 'notistack';

import { ThemeProvider, useTheme, createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';

import { MaskedField } from '@shared/components/MaskedField';
import request from '@shared/services/api/requests';
import DateTimeSelector from '@shared/components/DateTimeSelector';

const mainTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-size: '1rem';
        }
      `,
    },
  },
});

export default function MessagingOptIn() {
  const [name, setName] = React.useState('');
  const [checked, setChecked] = React.useState(false);
  const [birthdate, setBirthdate] = React.useState(null);
  const [birthdateError, setBirthdateError] = React.useState(true);
  const [phone, setPhone] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [submitted, setSubmitted] = React.useState(false);
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const data = JSON.parse(document.getElementById('reactData').innerHTML);

  async function handleSubmit() {
    const response = await request({
      url: `/messaging_opt_in/${data.location.custom_url_name}`,
      method: 'POST',
      data: {
        name,
        birthdate,
        phone,
        email,
      },
    });
    if (response.error) {
      enqueueSnackbar(`Error saving opt in preference: ${response.error}`, { variant: 'error' });
      return false;
    }
    setSubmitted(true);
    return true;
  }

  const isFormComplete = name.length !== 0 && phone.length >= 14 && !birthdateError && checked;

  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <Container>
        {(submitted) && (
        <Box m={2} textAlign="center">
          <Alert severity="success">Success: Message preference saved</Alert>
        </Box>
        )}
        <Box my={2} textAlign="center">
          <Typography sx={{ fontSize: '1.5rem' }}>
            {data.location?.name?.replace(/[^a-zA-Z0-9, ]/g, '')}
          </Typography>
        </Box>
        <Paper sx={{
          fontSize: '1rem',
          padding: 4,
          display: 'flex',
          flexDirection: 'column',
          width: '80%',
          margin: '0 auto',
          boxShadow: '0px 4px 20px rgb(0 0 0 / 20%)',
          [theme.breakpoints.up('md')]: {
            width: '50%',
          },
        }}
        >
          <Box sx={{ mb: 3, textAlign: 'center' }}>
            <Typography sx={{ fontSize: '1.25rem' }}>
              Messaging Opt-In
            </Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              label="Name"
              name="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              variant="outlined"
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <DateTimeSelector
              dateLabel="Birthday"
              dateTime={birthdate}
              minDate={null}
              maxDate={new Date()}
              setDateTime={setBirthdate}
              showTime={false}
              error={setBirthdateError}
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <MaskedField
              label="Cell Phone Number"
              mask="(#00) 000-0000"
              placeholder="(555) 555-5555"
              definitions={{
                '#': /[1-9]/,
              }}
              value={phone}
              setValue={setPhone}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              label="Email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <FormGroup>
              <FormControlLabel
                sx={{ alignItems: 'flex-start' }}
                control={<Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)} />}
                label={(
                  <Box>
                    <Box mb={1}>
                      <Typography>
                        I would like to receive messages and emails and updates about my appointments and care.
                      </Typography>
                    </Box>
                    <Box mb={1}>
                      <Typography>
                        Message and email frequency may vary.
                      </Typography>
                      <Typography>
                        Message and email and data rates may apply.
                      </Typography>
                    </Box>
                    <Box mb={1}>
                      <Typography>
                        Reply STOP to opt out.
                      </Typography>
                    </Box>
                  </Box>
            )}
              />
            </FormGroup>
            <Typography sx={{ pt: 2 }}>
              Your privacy matters: We won't sell your data. Read our
              {' '}
              <Link href="/legal/privacy_policy" target="_blank" underline="hover">Privacy Policy</Link>
              .
            </Typography>
          </Box>
          <Button onClick={handleSubmit} disabled={!isFormComplete || submitted} variant="contained">Sign Up</Button>
        </Paper>
      </Container>
    </ThemeProvider>
  );
}
